import { createSlice } from "@reduxjs/toolkit";
import { getAgentProfitability } from "./getAgentProfitability";
import { getProfitReport } from "./getProfitChartData";
import { getTransactions } from "./getTransactions";
const initialState = {
  agentProfitability: {
    success: false,
    data: [],
    stats: {},
    isLoading: true,
    errMsg: null,
  },
  chartData: {
    success: false,
    data: {},
    isLoading: true,
    errMsg: null,
  },
  transactions: {
    success: false,
    data: [],
    count: 0,
    isLoading: false,
    errMsg: null,
  },
};

const ProfitReport = createSlice({
  name: "ProfitReport",
  initialState,
  extraReducers: {
    [getAgentProfitability.pending]: (state) => {
      state.agentProfitability.isLoading = true;
      state.agentProfitability.errMsg = null;
      state.agentProfitability.success = false;
    },
    [getAgentProfitability.fulfilled]: (state, action) => {
      state.agentProfitability.success = true;
      state.agentProfitability.isLoading = false;
      state.agentProfitability.data = action.payload?.agents_data;
      state.agentProfitability.stats = action.payload?.totals;
    },
    [getAgentProfitability.rejected]: (state, action) => {
      state.agentProfitability.isLoading = false;
      state.agentProfitability.success = false;
      state.agentProfitability.errMsg = action.payload;
    },
    [getProfitReport.pending]: (state) => {
      state.chartData.isLoading = true;
      state.chartData.errMsg = null;
      state.chartData.success = false;
    },
    [getProfitReport.fulfilled]: (state, action) => {
      state.chartData.success = true;
      state.chartData.isLoading = false;
      state.chartData.data = action.payload;
    },
    [getProfitReport.rejected]: (state, action) => {
      state.chartData.isLoading = false;
      state.chartData.success = false;
      state.chartData.errMsg = action.payload;
    },

    [getTransactions.pending]: (state) => {
      state.transactions.isLoading = true;
      state.transactions.errMsg = null;
      state.transactions.success = false;
    },
    [getTransactions.fulfilled]: (state, action) => {
      state.transactions.success = true;
      state.transactions.isLoading = false;
      if (action.payload.pageNumber > 1) {
        state.transactions.data = [
          ...state.transactions.data,
          ...action.payload.data,
        ];
      } else {
        state.transactions.data = action.payload.data;
      }
      state.transactions.count = action.payload.count;
    },
    [getTransactions.rejected]: (state, action) => {
      state.transactions.isLoading = false;
      state.transactions.success = false;
      state.transactions.errMsg = action.payload;
    },
  },
});
export default ProfitReport.reducer;
export * from "./getAgentProfitability";
export * from "./getProfitChartData";
export * from "./getTransactions";
