// store.js
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import dashboard from "./dashboard";
import adminAuth from "./auth";
import recentCalls from "./calls/getRecentCalls";
import contactList from "./calls/getContactList";
import agentList from "./agents/getAgentList";
import docList from "./docs/getDocList";
import docDetails from "./docs/getDocDetails";
import allAgent from "./agents/getAllAgent";
import agentProfile from "./agents/getAgentProfile";
import emailTemplates from "./agents/agentRecruitment/getAllTemplates";
import textTemplates from "./agents/agentRecruitment/getTextTemplates";
import leadList from "./leads/getLeadList";
import textMessageList from "./leads/getTextMessageList";
import leadProfile from "./leads/getLeadProfile";
import slackChannels from "./leads/leadRouting/getSlackChannels";
import actionPlans from "./leads/leadRouting/actionPlans";
import LeadRoutingGroups from "./leads/leadRouting/groups";
import LeadRoutingOverView from "./leads/leadRouting/routing";
import timelines from "./agents/getAllTimelines";
import agentAverageStats from "./agents/getAgentAverageStats";
import project from "./projects/project";
import smartlists from "./smartList";
import userStaffDepartment from "./common/userStaffDepartment";
import agentRosterList from "./agents/agentRoster/getAgentRosterList";
import agentReviews from "./agents/agentRoster/agentReviews";
import AgentTrainingIssues from "./agents/agentRosterDetail/trainingIssue";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import agentRosterProfile from "./agents/agentRoster/getAgentRosterProfile";
import agentRecruitmentTagsList from "./agents/agentRecruitment/getAgentsRecruitmentAllTags";
import leadsTagsList from "./leads/getLeadsAllTags";
import agentRecruitmentOfficesList from "./agents/agentRecruitment/getAgentsRecruitmentAllOffices";
import agentsRecruitmentStages from "./agents/agentRecruitment/getAgentsRecruitmentStages";
import agentsRecruitmentCities from "./agents/agentRecruitment/getAgentsRecruitmentCities";
import agentsRecruitmentZipcodes from "./agents/agentRecruitment/getAgentsRecruitmentZipcodes";
import agentTasks from "./agentTasks";
import getAllUsersListToUseInAllSections from "./users/getAllUsersListToUseInAllSections";
// import getCommissionPlans from "./calls/getCommissionPlans";
import getUnprocessedClosings from "./finance/getUnprocessedClosings";
import getPendingChecks from "./finance/getPendingChecks";
import getProcessedACH from "./finance/getProcessedACH";
import getClosingInfo from "./finance/getClosingInfo";
import getProcessedClosing from "./finance/getProcessedClosing";
import getLeadRouting from "./leads/getLeadRouting";

import Listings from "./listings/listings";
import ListingDetail from "./listings/listingDetail";
import FMLSListings from "./listings/listingFmlsDetail";
import ListingMakeover from "./listings/makeover";
import ListingPublicData from "./listings/publicData";

import agentInventory from "./agents/agentRosterDetail/inventory";

import InventoryData from "./inventory";

import teams from "./teams/team";
import teamLeaders from "./teams/teamLeaders";

import AgentTabs from "./agents/agentRosterDetail/agentTabs";
import AgentAchievement from "./agents/agentRosterDetail/achievements";
import AgentPasswords from "./agents/agentRosterDetail/password";
import AgentPoints from "./agents/agentRosterDetail/points";

import TransactionsList from "./transactions";
import TransactionsContract from "./transactions/getContract";
import Tasks from "./transactions/tasks";
import TasksTemplates from "./transactions/taskTemplates";

import stagesList from "./agents/agentRecruitment/getAgentsRecruitmentStages";
import appointmentTypes from "./agents/agentRecruitment/getRecruitmentAppointmentTypes";
import AgentNotes from "./agents/agentRosterDetail/notes";
import AddCommissionPlan from "./agents/agentRosterDetail/attachCommissionPlan";
import AttachedCommissionPlan from "./agents/agentRosterDetail/getAttachedPlans";
import AgentTrainingProgress from "./agents/agentRosterDetail/trainingProgress";
import commissionPlans from "./finance/commissionPlans";
import agentBusinessCardDetail from "./agents/agentRosterDetail/businessCardDetail";
import paymentRecords from "./agents/agentRosterDetail/getPaymentRecords";
import leadTimeline from "./leads/leadTimeline";
import leadTags from "./leads/getLeadTags";
import leadStageList from "./leads/leadStageList";

import propertyList from "./propertyListing";

import getProperties from "./common/getProperties";
import getAllForms from "./docs/getAllForms";
import allCmas from "./tools/cmas";
import getPendingPayments from "./finance/getPendingPayments";
import getProcessedClosings from "./finance/getProcessedClosings";

// vendors
import VendorsList from "./vendors/vendorList";
import VendorDetail from "./vendors/vendorDetail";

import LeadEmailTemplates from "./leads/templates";
import LeadSavedSearches from "./leads/savedSearches";
import LeadTextTemplates from "./leads/textTemplates";
import LeadsInbox from "./leads/inbox";
//phone configuration
import PhoneNumbers from "./phoneConfiguration/phoneNumbers";
import PhoneQueues from "./phoneConfiguration/queue";
import PhoneExtensions from "./phoneConfiguration/extensions";
import PhoneAudios from "./phoneConfiguration/audios";
import powerSearch from "./powerSearch";
// global widgets
import globalWidgets from "./widgets";
// reportings
import CallsReportingList from "./reporting/calls";
import AgentsReportingList from "./reporting/agentActivities";
import ProfitReport from "./reporting/profitability";
// event and alerts
import Events from "./eventsAndAlerts/events";
import Alerts from "./eventsAndAlerts/alerts";
import AlertTemplates from "./eventsAndAlerts/template";
// messages and alerts
import MessagesAndAlerts from "./messageAndAlerts";

// agent requests
import AgentRequests from "./agentRequests";

// knowledge base
import KnowledgeBaseCourses from "./knowledge/courses";
import KnowledgeBasePosts from "./knowledge/posts";
import KnowledgeBaseCategories from "./knowledge/categories";
import knowledgeBaseSearch from "./knowledge/knowledgeSearch";
import stagesListLeads from "./leads/getPeopleStages";

// marketing center
import MarketingCenterDigital from "./marketingCenter/digital";

// send email as
import SendEmailAs from "./sendEmailAs";
// ponds
import Ponds from "./ponds";

// staff management
import Staff from "./staff";
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["allAgent"],
};
const widgetsConfig = {
  key: "widgets",
  storage,
  whitelist: ["minimizedFooterTabs"],
};
const agentListConfig = {
  key: "agentList",
  storage: storage,
  whitelist: ["sorting", "filters", "columnsOrder", "columnsVisibility"],
};

const peopleLeadsListConfig = {
  key: "leadList",
  storage: storage,
  whitelist: ["sorting", "filters", "columnsOrder", "columnsVisibility"],
};

const emailTemplatesConfig = {
  key: "emailTemplates",
  storage: storage,
  whitelist: ["savedEmailTemplates"],
};

const textTemplatesConfig = {
  key: "textTemplates",
  storage: storage,
  whitelist: ["savedTextTemplates"],
};

const rootReducer = combineReducers({
  dashboard,
  adminAuth,
  recentCalls,
  contactList,
  agentList: persistReducer(agentListConfig, agentList),
  agentProfile,
  agentAverageStats,
  docList,
  allAgent,
  project,
  agentTasks,
  userStaffDepartment,
  docDetails,
  getProperties,
  getAllForms,
  // getCommissionPlans,
  propertyList,
  getProcessedClosing,
  getLeadRouting,
  smartlists,
  Staff,
  users: combineReducers({
    storedListOfUsers: getAllUsersListToUseInAllSections,
  }),
  finance: combineReducers({
    commissionPlans: commissionPlans,
    unprocessedClosings: getUnprocessedClosings,
    closingInfo: getClosingInfo,
    processedClosings: getProcessedClosings,
    pendingPayments: getPendingPayments,
    achData: getProcessedACH,
    checksData: getPendingChecks,
  }),
  phoneConfiguration: combineReducers({
    PhoneNumbers: PhoneNumbers,
    PhoneQueues: PhoneQueues,
    PhoneExtensions: PhoneExtensions,
    PhoneAudios: PhoneAudios,
  }),
  teams: combineReducers({
    teams: teams,
    teamLeaders: teamLeaders,
  }),
  transactions: combineReducers({
    TransactionsList: TransactionsList,
    TransactionsContract: TransactionsContract,
    Tasks: Tasks,
    templates: TasksTemplates,
  }),
  listings: combineReducers({
    ListingsData: Listings,
    listingDetail: ListingDetail,
    FMLSListDetail: FMLSListings,
    makeover: ListingMakeover,
    publicData: ListingPublicData,
  }),
  reporting: combineReducers({
    calls: CallsReportingList,
    agents: AgentsReportingList,
    profit: ProfitReport,
  }),
  leads: combineReducers({
    EmailTemplates: LeadEmailTemplates,
    TextTemplates: LeadTextTemplates,
    inbox: LeadsInbox,
    LeadRoutingGroups: LeadRoutingGroups,
    LeadRoutingOverView: LeadRoutingOverView,
    slackChannels: slackChannels,
    actionPlans: actionPlans,
    leadsStages: stagesListLeads,
    leadsTagsList: leadsTagsList,
    savedSearches: LeadSavedSearches,
  }),
  Ponds: Ponds,
  vendors: combineReducers({
    VendorsList: VendorsList,
    VendorDetail: VendorDetail,
  }),
  agentRecruitment: combineReducers({
    agentRecruitmentTagsList: agentRecruitmentTagsList,
    agentRecruitmentOfficesList: agentRecruitmentOfficesList,
    agentsRecruitmentStages: agentsRecruitmentStages,
    agentsRecruitmentCities: agentsRecruitmentCities,
    agentsRecruitmentZipcodes: agentsRecruitmentZipcodes,
    appointmentTypes: appointmentTypes,
  }),
  agentRoster: combineReducers({
    agentRosterList: agentRosterList,
    agentReviews: agentReviews,
    agentRosterProfile: agentRosterProfile,
    AddCommissionPlan: AddCommissionPlan,
    AttachedCommissionPlan: AttachedCommissionPlan,
    AgentTrainingProgress: AgentTrainingProgress,
    agentBusinessCardDetail: agentBusinessCardDetail,
    paymentRecords: paymentRecords,
    AgentTrainingIssues: AgentTrainingIssues,
    notes: AgentNotes,
    AgentTabs: AgentTabs,
    AgentAchievement: AgentAchievement,
    points: AgentPoints,
    AgentPasswords: AgentPasswords,
    agentInventory: agentInventory,
  }),
  inventory: combineReducers({
    InventoryData: InventoryData,
  }),
  eventAlerts: combineReducers({
    events: Events,
    alerts: Alerts,
    templates: AlertTemplates,
  }),
  // knowledge base
  knowledgeBase: combineReducers({
    posts: KnowledgeBasePosts,
    courses: KnowledgeBaseCourses,
    categories: KnowledgeBaseCategories,
    search: knowledgeBaseSearch,
  }),
  // marketing center
  marketingCenter: combineReducers({
    digital: MarketingCenterDigital,
  }),
  // send email as
  SendEmailAs,
  // emailTemplates,
  emailTemplates: persistReducer(emailTemplatesConfig, emailTemplates),
  timelines,
  stagesList,
  // textTemplates,
  textTemplates: persistReducer(textTemplatesConfig, textTemplates),
  widgets: persistReducer(widgetsConfig, globalWidgets),
  leadList: persistReducer(peopleLeadsListConfig, leadList),
  textMessageList,
  leadProfile,
  leadTimeline,
  leadTags,
  allCmas,
  leadStageList,
  powerSearch,
  MessagesAndAlerts: MessagesAndAlerts,
  AgentRequests: AgentRequests,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore(
  {
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== "production",
  }
  // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
export const persistor = persistStore(store);
