import React, { useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import {
  Button,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemAvatar,
  OutlinedInput,
  Divider,
  InputAdornment,
  IconButton,
  ListItemSecondaryAction,
  Checkbox,
  Typography,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SearchIcon from "@mui/icons-material/Search";
import { useSelector } from "react-redux";
import Avatar from "../../../../components/uiElements/avatar";
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    PaperProps={{
      elevation: 0,
      sx: {
        overflow: "visible",
        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
        mt: 1.5,
        "&:before": {
          content: '""',
          display: "block",
          position: "absolute",
          top: 0,
          right: 14,
          width: 10,
          height: 10,
          bgcolor: "background.paper",
          transform: "translateY(-50%) rotate(45deg)",
          zIndex: 0,
        },
      },
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 200,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

function UserSelectionsForStats(props) {
  let { classes, handleUpdateUserFilters, defaultValue, filters } = props;
  const [searchText, setSearchText] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [agentIds, setAgentIds] = useState(filters?.agent_ids || []);
  const [teamIds, setTeamIds] = useState(filters?.team_id || []);
  const [isChangedFlag, setIsChangedFlag] = useState(false);
  const open = Boolean(anchorEl);
  const storedListOfUsers = useSelector((item) => item.users.storedListOfUsers);
  const adminAuth = useSelector((state) => state.adminAuth);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const getTeamLeaderId = (teamId) => {
    return storedListOfUsers.teamsList?.find((item) => item.id === teamId)
      ?.team_leader_id;
  };

  const getTeamName = () => {
    if (teamIds?.length) {
      let teamNames = [];
      teamIds?.map((teamId) => {
        let team = storedListOfUsers.teamsList?.find(
          (item) => item.id === teamId
        );
        if (team?.name) {
          teamNames.push(team?.name);
        }
      });
      return teamNames?.join(", ");
    } else {
      return "";
    }
  };

  const getAgentNames = () => {
    if (agentIds?.length) {
      let usersName = [];

      agentIds?.map((userId) => {
        let user = storedListOfUsers.list?.find((item) => item.id === userId);
        if (user?.full_name) {
          usersName.push(user?.full_name);
        }
      });

      return usersName?.join(", ");
    } else {
      return "";
    }
  };

  const filteredList = (userList, type) => {
    if (searchText) {
      if (type === "teams") {
        return userList.filter((item) =>
          item.name
            .toLowerCase()
            ?.slice(0, searchText.length)
            ?.includes(searchText)
        );
      } else {
        if (adminAuth?.adminDetail?.role?.name === "Team Leader") {
          return userList?.filter(
            (it) => getTeamLeaderId(it.team_id) === adminAuth?.adminDetail?.id
          );
        } else {
          return userList.filter((item) =>
            item.full_name
              .toLowerCase()
              ?.slice(0, searchText.length)
              ?.includes(searchText)
          );
        }
      }
    } else {
      if (type === "teams") {
        return userList;
      } else {
        if (adminAuth?.adminDetail?.role?.name === "Team Leader") {
          return userList?.filter(
            (it) => getTeamLeaderId(it.team_id) === adminAuth?.adminDetail?.id
          );
        } else {
          return userList;
        }
      }
    }
  };
  const CUstomSortUsers = (users) => {
    let agents = users.filter((item) => item?.role?.name === "Agents");
    return [...agents];
  };
  const handleUpdateFilter = () => {
    if (isChangedFlag) {
      if (teamIds?.length || agentIds?.length) {
        handleUpdateUserFilters(
          {
            agentIds,
            teamIds,
          },
          "users"
        );
      } else {
        handleUpdateUserFilters({}, "everyOne");
      }
    }
  };
  const handleApplyFilters = () => {
    setAnchorEl(null);
    handleUpdateFilter();
    setIsChangedFlag(false);
  };
  return (
    <div>
      <Button
        id="customer-date-picker-dropdown"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        className={classes.dateDropdownButtonStyle}
        disableElevation
        onClick={handleClick}
        endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        title={
          teamIds?.length || agentIds?.length
            ? `${getTeamName()}${
                getTeamName() && getAgentNames() ? ", " : ""
              }${getAgentNames()}`
            : defaultValue
        }
      >
        <Typography className={classes.dateDropdownButtonStyleContent}>
          {teamIds?.length || agentIds?.length
            ? `${getTeamName()}${
                getTeamName() && getAgentNames() ? ", " : ""
              }${getAgentNames()}`
            : defaultValue}
        </Typography>
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleApplyFilters}
      >
        <Box sx={{ height: "320px", overflowY: "auto", width: "350px" }}>
          <Box className={classes.SearchButtonSection}>
            <OutlinedInput
              value={searchText}
              onChange={(e) => setSearchText(e.target.value.toLowerCase())}
              fullWidth
              size="small"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton edge="end">
                    <SearchIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              }
              name="reporting-user-selecting"
              placeholder="Search user"
            />
            <Button
              onClick={handleApplyFilters}
              className={classes.applyButton}
              variant="contained"
            >
              Apply
            </Button>
          </Box>
          <Divider />
          <List
            dense
            sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          >
            {adminAuth?.adminDetail?.role?.name === "Team Leader" ? null : (
              <ListItem
                onClick={() => {
                  setTeamIds([]);
                  setAgentIds([]);
                  setIsChangedFlag(true);
                }}
                disablePadding
              >
                <ListItemButton>
                  <ListItemText primary={`Everyone`} />
                </ListItemButton>
              </ListItem>
            )}

            {/* 
            teamsList
            */}

            {adminAuth?.adminDetail?.role?.name === "Team Leader" ? null : (
              <ListItem disablePadding>
                <ListItemButton
                  sx={{ "& .MuiTypography-root": { fontWeight: "600" } }}
                >
                  <ListItemText primary={`Teams`} />
                </ListItemButton>
              </ListItem>
            )}

            {adminAuth?.adminDetail?.role?.name === "Team Leader"
              ? null
              : filteredList(storedListOfUsers.teamsList, "teams").map(
                  (item, value) => {
                    const labelId = `checkbox-list-secondary-label-${value}`;
                    return (
                      <ListItem
                        onClick={() => {
                          if (teamIds?.includes(item.id)) {
                            setTeamIds((preTeams) =>
                              [...preTeams]?.filter((id) => id !== item.id)
                            );
                          } else {
                            setTeamIds((preTeams) => [...preTeams, item.id]);
                          }
                          setIsChangedFlag(true);
                        }}
                        key={value}
                        disablePadding
                      >
                        <ListItemSecondaryAction>
                          <Checkbox
                            key={item.id}
                            checked={teamIds?.includes(item.id) ? true : false}
                          />
                        </ListItemSecondaryAction>
                        <ListItemButton>
                          <ListItemAvatar>
                            <Avatar
                              sx={{
                                width: "30px",
                                height: "30px",
                                fontSize: "13px",
                              }}
                              src={""}
                            >
                              {`${item.name?.slice(0, 1) || ""}${
                                item.name?.split(" ")[1]?.slice(0, 1) || ""
                              }`}
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText id={labelId} primary={`${item.name}`} />
                        </ListItemButton>
                      </ListItem>
                    );
                  }
                )}
            {/* Users List */}
            <ListItem disablePadding>
              <ListItemButton
                sx={{ "& .MuiTypography-root": { fontWeight: "600" } }}
              >
                <ListItemText primary={`Users`} />
              </ListItemButton>
            </ListItem>

            {CUstomSortUsers(filteredList(storedListOfUsers.list, "users")).map(
              (item, value) => {
                const labelId = `checkbox-list-secondary-label-${value}`;
                return (
                  <ListItem
                    onClick={() => {
                      if (agentIds.includes(item.id)) {
                        setAgentIds((preIds) =>
                          [...preIds]?.filter((it) => it !== item.id)
                        );
                      } else {
                        setAgentIds((preIds) => [...preIds, item.id]);
                      }
                      setIsChangedFlag(true);
                    }}
                    key={value}
                    disablePadding
                  >
                    <ListItemSecondaryAction>
                      <Checkbox
                        key={item.id}
                        checked={agentIds.includes(item.id) ? true : false}
                      />
                    </ListItemSecondaryAction>
                    <ListItemButton>
                      <ListItemAvatar>
                        <Avatar
                          sx={{
                            width: "30px",
                            height: "30px",
                            fontSize: "13px",
                          }}
                          src={item?.profile_images?.profile_img}
                        >
                          {item.first_name?.slice(0, 1)}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        id={labelId}
                        primary={`${item.first_name} ${item.last_name}`}
                      />
                    </ListItemButton>
                  </ListItem>
                );
              }
            )}
          </List>
        </Box>
      </StyledMenu>
    </div>
  );
}
export default withStyles(styleSheet, { name: "UserSelectionsForStatsStyle1" })(
  UserSelectionsForStats
);
