import * as React from "react";
import { Route, Routes } from "react-router-dom";
import UserReporting from "./mainView";
import ProfitAbilityTransactions from "./transactions";
const ProfitAbilityPage = (props) => {
  return (
    <Routes>
      <Route exact path="/" element={<UserReporting {...props} />} />
      <Route
        exact
        path="/transactions"
        element={<ProfitAbilityTransactions {...props} />}
      />
    </Routes>
  );
};
export default ProfitAbilityPage;
