import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const getAgentProfitability = createAsyncThunk(
  "ProfitReport/getAgentProfitability",
  async (data, thunkAPI) => {
    try {
      const resp = await axios.get(
        `${
          process.env.REACT_APP_BASE_URL
        }api/users/profitability/agents?start_date=${data.fromDate}&end_date=${
          data.lastDate
        }&team_id=${
          data.team_id?.length ? JSON.stringify(data.team_id) : ""
        }&agent_ids=${
          data?.agent_ids?.length ? JSON.stringify(data.agent_ids) : ""
        }`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return resp.data.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(
          error.response.data.errorMessage || error.response.data.message
        );
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);
