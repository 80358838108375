import React, { useEffect } from "react";
import { Box, Container, Typography, Paper, Stack } from "@mui/material";
import { withStyles } from "@mui/styles";
import { styleSheet } from "../style.js";
import { DataGridPro } from "@mui/x-data-grid-pro";
import TableSkeleton from "../../../../components/loader/tableLoader.jsx";
import Avatar from "../../../../components/uiElements/avatar/index.jsx";
import { useSelector, useDispatch } from "react-redux";
import { getTransactions } from "../../../../redux/reporting/profitability";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { NumberFormat } from "../../../../utils/numberFormat";
function ProfitAbilityTransactions(props) {
  const { classes } = props;
  const location = useLocation();
  const dispatch = useDispatch();
  const transactions = useSelector(
    (state) => state.reporting.profit.transactions
  );
  const columns = [
    {
      field: "name",
      headerName: "Name",
      renderCell: (params) => {
        return (
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Avatar
              sx={{ width: "30px", height: "30px", fontSize: "14px" }}
            >{`${params.row.agent_name
              .split("")[0]
              ?.slice(0, 1)}${params.row.agent_name
              .split(" ")[1]
              ?.slice(0, 1)
              ?.toUpperCase()}`}</Avatar>
            <Box> {params.row.agent_name}</Box>
          </Stack>
        );
      },
      minWidth: 220,
      flex: 1,
      editable: false,
      sortable: true,
    },
    {
      field: "property_address",
      headerName: "Property Address",
      valueGetter: (params) => params.row.property_address,
      editable: false,
      sortable: true,
      minWidth: 250,
      flex: 1,
    },
    {
      field: "closing_date",
      headerName: "Closing Date",
      flex: 1,
      minWidth: 130,
      renderCell: ({ row }) => {
        return row.closing_date ? (
          <Typography>
            {moment(row.closing_date).format("MMM D, YYYY")}
          </Typography>
        ) : (
          ""
        );
      },
      editable: false,
      sortable: true,
    },
    {
      field: "contractCost",
      headerName: "Contract Cost",
      renderCell: ({ row }) => {
        return row.contractCost ? (
          <Typography>
            {NumberFormat({
              number: row.contractCost,
              maximumFractionDigits: 0,
              currency: "USD",
            })}
          </Typography>
        ) : (
          "0"
        );
      },
      valueGetter: (params) =>
        params.row.contractCost ? Number(params.row.contractCost) : "0",
      editable: false,
      sortable: true,
      minWidth: 130,
      flex: 1,
    },
    {
      field: "price",
      headerName: "Price",
      minWidth: 130,
      flex: 1,
      editable: false,
      sortable: true,
      renderCell: ({ row }) => {
        return row.price ? (
          <Typography>
            {NumberFormat({
              number: row.price,
              maximumFractionDigits: 0,
              currency: "USD",
            })}
          </Typography>
        ) : (
          "0"
        );
      },
      valueGetter: (params) =>
        params.row.price ? Number(params.row.price) : "0",
    },
    {
      field: "totalGCI",
      headerName: "Total GCI",
      minWidth: 130,
      flex: 1,
      renderCell: ({ row }) => {
        return row.totalGCI ? (
          <Typography>
            {NumberFormat({
              number: row.totalGCI,
              maximumFractionDigits: 0,
              currency: "USD",
            })}
          </Typography>
        ) : (
          "0"
        );
      },
      valueGetter: (params) =>
        params.row.totalGCI ? Number(params.row.totalGCI) : "0",
      editable: false,
      sortable: true,
    },
    {
      field: "totalTeamLeaderCommission",
      headerName: "Total Team Leader Commission",
      width: 230,
      renderCell: ({ row }) => {
        return row.totalTeamLeaderCommission ? (
          <Typography>
            {NumberFormat({
              number: row.totalTeamLeaderCommission,
              maximumFractionDigits: 0,
              currency: "USD",
            })}
          </Typography>
        ) : (
          "0"
        );
      },
      valueGetter: (params) =>
        params.row.totalTeamLeaderCommission
          ? Number(params.row.totalTeamLeaderCommission)
          : "0",
      editable: false,
      sortable: true,
      flex: 1,
    },
    {
      field: "totalCompanyDollars",
      headerName: "Total Company Dollars",
      minWidth: 130,
      flex: 1,
      renderCell: ({ row }) => {
        return row.totalCompanyDollars ? (
          <Typography>
            {NumberFormat({
              number: row.totalCompanyDollars,
              maximumFractionDigits: 0,
              currency: "USD",
            })}
          </Typography>
        ) : (
          "0"
        );
      },
      valueGetter: (params) =>
        params.row.totalCompanyDollars
          ? Number(params.row.totalCompanyDollars)
          : "0",
      editable: false,
      sortable: true,
    },
  ];

  useEffect(() => {
    dispatch(
      getTransactions({
        agent_ids: location?.state?.agent_ids,
        start_date: location.state?.start_date,
        end_date: location.state?.end_date,
        team_id: location?.state?.team_id,
        pageNumber: 1,
      })
    );
  }, []);
  return (
    <Box className={classes.root}>
      <Container>
        {" "}
        <br />
        <Typography
          className={classes.agentActivitiesReportHeading}
          variant="h4"
        >
          {transactions.count} Transactions
        </Typography>
        <br />
        <Paper className={classes.tableRoot}>
          <DataGridPro
            rows={transactions.data?.length ? transactions.data : []}
            loading={transactions.isLoading}
            columns={columns}
            rowHeight={56}
            components={{
              LoadingOverlay: TableSkeleton,
            }}
            onRowsScrollEnd={(e) => {
              if (
                transactions?.count > e.virtualRowsCount &&
                !transactions.isLoading
              ) {
                dispatch(
                  getTransactions({
                    agent_ids: location?.state?.agent_ids,
                    start_date: location.state?.start_date,
                    end_date: location.state?.end_date,
                    team_id: location?.state?.team_id,
                    pageNumber: Number(e.virtualRowsCount / 20) + 1,
                  })
                );
              }
            }}
          />
        </Paper>
      </Container>
    </Box>
  );
}
export default withStyles(styleSheet, {
  name: "ProfitAbilityTransactionsStyle",
})(ProfitAbilityTransactions);
