import { Skeleton, Box, Card } from "@mui/material";
import { withStyles } from "@mui/styles";
import { styleSheet } from "../reporting/agentActivity/style";
function ActivityStatsSkelton(props) {
  let { classes, height, width } = props;
  return (
    <Box className={classes.agentActivitiesStatsArea}>
      {Array.from(Array(width ? 8 : 5).keys()).map(() => (
        <Card
          sx={{
            padding: "0px",
            height: height ? height : "",
            width: width ? width : "",
          }}
          variant="outlined"
          className={classes.agentActivitiesStatsItem}
        >
          <Skeleton
            animation="wave"
            variant="rounded"
            width={"100%"}
            height="100%"
          />
        </Card>
      ))}
    </Box>
  );
}
export default withStyles(styleSheet, { name: "ActivityStatsSkeltonStyle" })(
  ActivityStatsSkelton
);
