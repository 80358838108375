import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Checkbox,
  Chip,
  CircularProgress,
} from "@mui/material";
import ButtonGroups from "../../../components/uiElements/buttonGroup";
import Avatar from "../../../components/uiElements/avatar";
import { useDispatch, useSelector } from "react-redux";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { departmentsColors } from "../utils/department";
import { updateTrainedTask } from "../../../redux/staff";
import LoadingButton from "@mui/lab/LoadingButton";
import ResponseAlert from "../../../components/responseAlert";
function ManageDepartment(props) {
  const { classes, selectedUser } = props;
  const [selectedTask, setSelectedTask] = useState({});
  const [selectedTrainedTasks, setSelectedTrainedTasks] = useState([]);
  const [taskTypes, setTaskTypes] = useState({});
  const [savedFlag, setSavedFlag] = useState(false);
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const dispatch = useDispatch();
  const trainedTask = useSelector((item) => item.Staff.trainedTask);
  const updateTrained = useSelector((item) => item.Staff.updateTrained);
  const handleSuccess = () => {
    setSavedFlag(true);
    setTimeout(() => {
      setSavedFlag(false);
    }, 3000);
  };

  const handleError = (error) => {
    setErrorAlert({
      errorMsg: JSON.stringify(error),
      errorType: "error",
      isOpen: true,
    });
  };

  const handleSave = () => {
    if (selectedTrainedTasks?.length) {
      dispatch(
        updateTrainedTask({
          user_id: selectedUser?.id,
          schema: selectedTrainedTasks,
          handleSuccess,
          handleError,
        })
      );
    }
  };

  useEffect(() => {
    if (!selectedTask?.id && trainedTask?.data?.trainedTasks?.length) {
      setSelectedTask(trainedTask?.data?.trainedTasks[1]);
      if (!taskTypes?.length) {
        setTaskTypes(trainedTask?.data?.trainedTasks[1]?.task_types[0]);
      }
    }
  }, [trainedTask?.data?.trainedTasks]);
  useEffect(() => {
    if (trainedTask?.data?.selectedTrainedTasks?.length) {
      let newTrainedTask = [];
      trainedTask?.data?.selectedTrainedTasks?.map((item) => {
        newTrainedTask.push({
          department_id: item?.department_id,
          task_type_id: item?.task_type?.id,
          task_name: item?.task_type?.task_name || item?.task_name,
          department: item?.department,
        });
      });
      if (newTrainedTask?.length) {
        setSelectedTrainedTasks(newTrainedTask);
      }
    }
  }, [trainedTask?.data?.selectedTrainedTasks?.length, selectedUser.id]);

  return trainedTask?.isLoading ? (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      {" "}
      <CircularProgress size={40} />
    </Box>
  ) : (
    <Box>
      {" "}
      <Box className={classes.departmentHeaderSection}>
        <Box className={classes.headerLeft}>
          <ButtonGroups
            id="department-button-menu"
            bgColor="#0B0909"
            bgColorHover="#0B0909"
            placement={"bottom"}
            height="38.5px"
            minWidth="180px"
            size="medium"
            color="white"
            value={selectedTask.value}
            options={trainedTask?.data?.trainedTasks?.map((it) => ({
              ...it,
              title: it.name,
              value: it.id,
            }))}
            onChangeMenu={(value, data) => {
              setSelectedTask({ id: value, value, ...data });
              setTaskTypes(data?.task_types[0]);
            }}
            placeholder="Listings"
            {...props}
          />
          <Box className={classes.headerUserDisplay}>
            <Typography className={classes.headerUserHeading}>
              Managing:
            </Typography>
            <Avatar
              src={selectedUser?.profile_images?.profile_img_thumbnail}
              alt={selectedUser?.full_name}
              variant="rounded"
              sx={{ width: "30px", height: "30px", fontSize: "14px" }}
            >
              {`${selectedUser?.first_name?.slice(
                0,
                1
              )}${selectedUser?.last_name?.slice(0, 1)}`}
            </Avatar>
            <Typography className={classes.headerUserHeading}>
              {selectedUser?.full_name}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.headerRight}>
          {savedFlag ? (
            <Typography className={classes.headerRightSuccess}>
              Changes saved <CheckRoundedIcon fontSize="small" />
            </Typography>
          ) : null}

          <LoadingButton
            onClick={handleSave}
            variant="outlined"
            color="inherit"
            size="small"
            loadingPosition="start"
            loading={updateTrained.isLoading}
          >
            Save
          </LoadingButton>
        </Box>
      </Box>
      <Box className={classes.departmentManageSection}>
        <Box className={classes.departmentManageSectionLeft}>
          <Box className={classes.headerSection}>
            <Typography className={classes.headerSectionHeading}>
              Projects
            </Typography>
          </Box>
          <Box className={classes.projectListing}>
            <List disablePadding className={classes.listSection}>
              {selectedTask?.task_types?.length
                ? selectedTask?.task_types?.map((item, index) => (
                    <ListItem onClick={() => setTaskTypes(item)} disablePadding>
                      <ListItemButton
                        selected={taskTypes?.id === item.id}
                        sx={{ borderRadius: "6px" }}
                      >
                        <ListItemText primary={item?.name} />
                      </ListItemButton>
                    </ListItem>
                  ))
                : null}
            </List>
          </Box>
        </Box>
        <Box className={classes.departmentManageSectionRight}>
          {" "}
          <Box className={classes.headerSection}>
            <Typography className={classes.headerSectionHeading}>
              Trained Tasks
            </Typography>
          </Box>
          <List
            sx={{
              width: "100%",
              bgcolor: "background.paper",
            }}
            disablePadding
            className={classes.listSection}
          >
            {taskTypes?.sub_task_type?.length
              ? taskTypes?.sub_task_type?.map((item) => {
                  const labelId = `checkbox-list-label-${item.id}`;
                  return (
                    <ListItem
                      key={item.id}
                      secondaryAction={
                        <Chip
                          className={classes.departmentViewChip}
                          label={item?.department}
                          size="small"
                          sx={{
                            backgroundColor:
                              departmentsColors?.find(
                                (it) => it.name === item.department
                              )?.backgroundColor || "#DDC441CC",
                          }}
                        />
                      }
                      divider
                      disablePadding
                    >
                      <ListItemButton
                        role={undefined}
                        // onClick={handleToggle(value)}
                        sx={{
                          backgroundColor: selectedTrainedTasks?.find(
                            (it) =>
                              it.task_type_id === taskTypes.id &&
                              it.task_name === item?.name
                          )
                            ? "#F2FFF4"
                            : "",
                        }}
                        dense
                      >
                        <ListItemIcon sx={{ minWidth: "40px" }}>
                          <Checkbox
                            edge="start"
                            // checked={checked.includes(value)}
                            tabIndex={-1}
                            color="success"
                            disableRipple
                            inputProps={{ "aria-labelledby": labelId }}
                            checked={
                              selectedTrainedTasks?.find(
                                (it) =>
                                  it.task_type_id === taskTypes.id &&
                                  it.task_name === item?.name
                              )
                                ? true
                                : false
                            }
                            onChange={(e) => {
                              if (e.target?.checked) {
                                setSelectedTrainedTasks((preTasks) => [
                                  ...preTasks,
                                  {
                                    department_id: selectedTask?.id,
                                    task_type_id: taskTypes.id,
                                    task_name: item?.name,
                                    department: item?.department,
                                  },
                                ]);
                              } else {
                                setSelectedTrainedTasks((preTasks) =>
                                  [...preTasks]?.filter(
                                    (tsk) => tsk?.task_name !== item.name
                                  )
                                );
                              }
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={item?.name} />
                      </ListItemButton>
                    </ListItem>
                  );
                })
              : null}
          </List>
        </Box>
      </Box>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </Box>
  );
}

export default ManageDepartment;
