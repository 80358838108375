import React, { useState, useEffect, useCallback } from "react";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import {
  Box,
  Typography,
  Card,
  Button,
  Stack,
  Divider,
  Checkbox,
  Skeleton,
  Container,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import UserSelectionsForStats from "./userSelectionDropdown";
import UserSelectionsForStats2 from "../../../components/uiElements/userSelectionDropdown";
import DatePickersForStats from "../../../components/uiElements/datePicker";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import ButtonDropdown from "../../../components/uiElements/buttonDropdownGeneral";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import AgentActivityChart from "../../tools/reporting/agentActivity/activityChart.js";
import {
  getAgentProfitability,
  getProfitReport,
} from "../../../redux/reporting/profitability";
import ActivityRecordsTable from "./ActivityTable.js";
import SquareRoundedIcon from "@mui/icons-material/SquareRounded";
import * as XLSX from "xlsx/xlsx.mjs";
import ActivityStatsSkelton from "../../tools/skelton/activityStats.js";
import { NumberFormat } from "../../../utils/numberFormat";

const moment = require("moment-timezone");

const customOptions = {
  responsive: true,
  tension: 0.3,
  interaction: {
    mode: "index",
    intersect: false,
  },
  stacked: false,
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        display: false,
      },
    },
    xAxes: [
      {
        display: true,
      },
    ],
    y: {
      type: "linear",
      display: true,
      position: "left",
    },
    y1: {
      type: "linear",
      display: true,
      position: "right",

      // grid line settings
      grid: {
        drawOnChartArea: false,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
      position: "top",
    },
    title: {
      display: false,
      text: "",
    },
  },
};
const menuOptions = [
  { title: "Time Period", value: "time_period" },
  { title: "Total Volume", value: "totalVolume" },
  { title: "Total Overhead", value: "totalOverhead" },
  { title: "Total GCI", value: "totalGCI" },
  { title: "Total Company Dollars", value: "totalCompanyDollars" },
  { title: "Total Closings", value: "totalClosings" },
  { title: "Total Team Leader Commission", value: "totalTeamLeaderCommission" },
  { title: "Net Profit", value: "netProfit" },
  { title: "Nothing", value: "nothing" },
];
export const granularityOptions = [
  {
    title: "Monthly",
    value: "month",
  },
  {
    title: "Quarterly",
    value: "quarter",
  },
  {
    title: "Yearly",
    value: "year",
  },
];
function UserReporting(props) {
  let { classes } = props;
  const [filters, setFilters] = useState(() => {
    if (localStorage.filters) {
      return {
        ...JSON.parse(localStorage.filters),
      };
    } else {
      return {
        fromDate: moment().tz("America/New_York").startOf("year").format(),
        lastDate: moment().tz("America/New_York").endOf("year").format(),
        team_id: "",
        granularity: { title: "Monthly", value: "month" },
        agent_ids: "",
      };
    }
  });

  const [previousPeriod, setPreviousPeriod] = useState({
    isChecked: false,
    fromDate: moment()
      .tz("America/New_York")
      .subtract(1, "year")
      .startOf("year")
      .format(),
    lastDate: moment()
      .tz("America/New_York")
      .subtract(1, "year")
      .endOf("year")
      .format(),
  });
  const [chartType1, setChartType1] = useState({
    title: "Net Profit",
    value: "netProfit",
  });
  const [chartType2, setChartType2] = useState({
    title: "Total GCI",
    value: "totalGCI",
  });
  const dispatch = useDispatch();
  const agentProfitability = useSelector(
    (state) => state.reporting.profit.agentProfitability
  );
  const chartData = useSelector((state) => state.reporting.profit.chartData);
  const adminAuth = useSelector((state) => state.adminAuth);
  const storedListOfUsers = useSelector((item) => item.users.storedListOfUsers);

  const getTeamId = () => {
    return storedListOfUsers.teamsList?.find(
      (item) => item.team_leader_id === adminAuth?.adminDetail?.id
    )?.id;
  };

  useEffect(() => {
    if (
      storedListOfUsers?.list?.length ||
      adminAuth?.adminDetail?.role?.name !== "Team Leader"
    ) {
      if (previousPeriod.isChecked) {
        dispatch(
          getProfitReport({
            ...filters,
            granularity: filters?.granularity?.value,
            timeSeries: true,
            fromDate: previousPeriod.fromDate.slice(0, 10) + "T00:00:01",
            lastDate: previousPeriod.lastDate.slice(0, 10) + "T23:59:59",
            team_id:
              adminAuth?.adminDetail?.role?.name === "Team Leader"
                ? [getTeamId()]
                : filters.team_id,
          })
        );
        dispatch(
          getAgentProfitability({
            ...filters,
            fromDate: previousPeriod.fromDate.slice(0, 10) + "T00:00:01",
            lastDate: previousPeriod.lastDate.slice(0, 10) + "T23:59:59",
            team_id:
              adminAuth?.adminDetail?.role?.name === "Team Leader"
                ? [getTeamId()]
                : filters.team_id,
          })
        );
      } else {
        dispatch(
          getProfitReport({
            ...filters,
            granularity: filters?.granularity?.value,
            timeSeries: true,
            fromDate: filters.fromDate.slice(0, 10) + "T00:00:01",
            lastDate: filters.lastDate.slice(0, 10) + "T23:59:59",
            team_id:
              adminAuth?.adminDetail?.role?.name === "Team Leader"
                ? [getTeamId()]
                : filters.team_id,
          })
        );
        dispatch(
          getAgentProfitability({
            ...filters,
            fromDate: filters.fromDate.slice(0, 10) + "T00:00:01",
            lastDate: filters.lastDate.slice(0, 10) + "T23:59:59",
            team_id:
              adminAuth?.adminDetail?.role?.name === "Team Leader"
                ? [getTeamId()]
                : filters.team_id,
          })
        );
      }
    }
  }, [filters, previousPeriod.isChecked, storedListOfUsers?.list?.length]);

  const handleUpdateLastPeriod = (data) => {
    let days = getDaysDifference(data);
    if (days === 30) {
      setPreviousPeriod({
        isChecked: false,
        fromDate: moment(data.fromDate)
          .tz("America/New_York")
          .subtract(1, "months")
          .format(),
        lastDate: moment(data.lastDate)
          .tz("America/New_York")
          .subtract(1, "months")
          .format(),
      });
    } else {
      setPreviousPeriod({
        isChecked: false,
        fromDate: moment(data.fromDate)
          .tz("America/New_York")
          .subtract(days + 1, "days")
          .format(),
        lastDate: moment(data.lastDate)
          .tz("America/New_York")
          .subtract(days + 1, "days")
          .format(),
      });
    }
  };

  const handleGetDates = (data) => {
    let days = getDaysDifference(data);
    let granularity = filters.granularity;
    if (days <= 365) {
      granularity = { title: "Monthly", value: "month" };
    } else if (days >= 365 && days <= 2190) {
      granularity = { title: "Quarterly", value: "quarter" };
    } else {
      granularity = { title: "Yearly", value: "year" };
    }
    setFilters((filter) => {
      return {
        ...filter,
        fromDate: data.fromDate,
        lastDate: data.lastDate,
        granularity: granularity,
      };
    });
    handleUpdateLastPeriod(data);
  };

  const handleUpdateUserFilters = (data, type) => {
    if (type === "everyOne") {
      setFilters({
        ...filters,
        team_id: "",
        agent_ids: "",
      });
    } else {
      setFilters({
        ...filters,
        team_id: data.teamIds,
        agent_ids: data.agentIds,
      });
    }
  };

  const getDaysDifference = (data) => {
    let a = moment(data.fromDate);
    let b = moment(data.lastDate);
    return b.diff(a, "days");
  };
  const handleSelectChart1 = (value) => {
    setChartType1(value);
  };
  const handleSelectChart2 = (value) => {
    setChartType2(value);
  };
  const handleSelectGranularity = (value) => {
    setFilters({
      ...filters,
      granularity: value,
    });
  };
  const handleMakeChartData = useCallback(() => {
    let activityData = chartData?.data;
    let datasets = [];
    if (chartType1.value !== "nothing") {
      let obj = {
        label: chartType1.title,
        data:
          activityData && activityData[chartType1.value]?.length
            ? activityData[chartType1.value]
            : [],
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        fill: {
          target: "origin", // 3. Set the fill options
          above: "rgba(255, 99, 132, 0.5)",
        },
        yAxisID: "y",
      };
      datasets.push(obj);
    }
    if (chartType2.value !== "nothing") {
      let obj = {
        label: chartType2.title,
        data:
          activityData && activityData[chartType2.value]?.length
            ? activityData[chartType2.value]
            : [],
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
        fill: {
          target: "origin", // 3. Set the fill options
          above: "rgba(53, 162, 235, 0.5)",
        },
        yAxisID: "y1",
      };
      datasets.push(obj);
    }
    let obj = {
      labels: activityData?.labels,
      datasets,
    };
    return obj;
  }, [
    filters.granularity,
    chartData?.data,
    chartType1.value,
    chartType2.value,
  ]);

  // const handleExport = () => {
  //   let usersData = agentProfitability.data?.agentRecords || [];
  //   let users = [["Name", "Calls", "Texts", "Notes", "Emails"]];
  //   usersData.forEach((item) => {
  //     let userArray = [
  //       item.name,
  //       item.calls,
  //       item.textMessages,
  //       item.notes,
  //       item.emails,
  //     ];
  //     users.push(userArray);
  //   });
  //   const wb = XLSX.utils.book_new();
  //   const wsAll = XLSX.utils.aoa_to_sheet(users);
  //   XLSX.utils.book_append_sheet(wb, wsAll, "All Users");
  //   XLSX.writeFile(wb, `agent-activity-export-${Date()}.csv`);
  // };
  const handleCalculatePreviousLabel = useCallback(() => {
    let days = getDaysDifference(filters);
    if (days === 0) {
      return `${moment(previousPeriod.fromDate)
        .tz("America/New_York")
        .format("MMM Do YY")} 12AM - ${moment(previousPeriod.lastDate)
        .tz("America/New_York")
        .format("MMM Do YY")} 11PM`;
    } else if (days === 6) {
      return `Week of ${moment(previousPeriod.fromDate)
        .tz("America/New_York")
        .format("ddd, MMM Do YYYY")} - Week of ${moment(previousPeriod.lastDate)
        .tz("America/New_York")
        .format("ddd, MMM Do YYYY")}`;
    } else {
      return `${moment(previousPeriod.fromDate)
        .tz("America/New_York")
        .format("ddd, MMM Do YYYY")} - ${moment(previousPeriod.lastDate)
        .tz("America/New_York")
        .format("ddd, MMM Do YYYY")}`;
    }
  }, [previousPeriod, filters]);

  return (
    <Box className={classes.root}>
      <Container>
        <Box className={classes.agentActivitiesScreenTopArea}>
          <Typography
            className={classes.agentActivitiesReportHeading}
            variant="h4"
          >
            Profitability Report
          </Typography>
          <Box className={classes.agentActivitiesScreenTopFilter}>
            {/* <Button
              onClick={handleExport}
              disableElevation
              className={classes.exportButton}
              variant="contained"
            >
              <DownloadRoundedIcon fontSize="small" />
            </Button> */}
            <Box sx={{ mr: "15px" }}>
              <UserSelectionsForStats
                defaultValue={"Everyone"}
                handleUpdateUserFilters={handleUpdateUserFilters}
                filters={filters}
                {...props}
              />
            </Box>
            <DatePickersForStats
              defaultValue="This Year"
              handleGetDates={handleGetDates}
              customOption={[
                {
                  title: "Custom Date",
                  value: "custom",
                },
                {
                  title: "This Year",
                  value: "currentYear",
                },
                {
                  title: "Last Year",
                  value: "lastYear",
                },
                {
                  title: "Last 2 Years",
                  value: "last2Year",
                },
                {
                  title: "Last 3 Years",
                  value: "last3Year",
                },
                {
                  title: "Last 4 Years",
                  value: "last4Year",
                },
                {
                  title: "Last 5 Years",
                  value: "last5Year",
                },
                {
                  title: "Last 10 Years",
                  value: "last10Year",
                },
                {
                  title: "All Time",
                  value: "allTime",
                },
              ]}
              filters={filters}
              {...props}
            />
          </Box>
        </Box>
        <Card variant="outlined" className={classes.agentActivitiesChartGraph}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            sx={{ mb: "15px" }}
            spacing={2}
          >
            <ButtonDropdown
              placeHolder="Select"
              options={menuOptions.filter(
                (item) =>
                  item.value !== chartType2.value &&
                  item.value !== chartType1.value
              )}
              value={chartType1}
              handleSelect={handleSelectChart1}
              startIcon={
                <SquareRoundedIcon
                  fontSize="small"
                  sx={{ color: "rgba(255, 99, 132, 0.5)" }}
                />
              }
            />
            <Typography>vs</Typography>
            <ButtonDropdown
              placeHolder="Select"
              options={menuOptions.filter(
                (item) =>
                  item.value !== chartType2.value &&
                  item.value !== chartType1.value
              )}
              value={chartType2}
              handleSelect={handleSelectChart2}
              startIcon={
                <SquareRoundedIcon
                  fontSize="small"
                  sx={{ color: "rgba(53, 162, 235, 0.5)" }}
                />
              }
            />
            <Divider orientation="vertical" flexItem />
            <ButtonDropdown
              placeHolder="Select"
              options={granularityOptions}
              value={filters?.granularity}
              handleSelect={handleSelectGranularity}
            />
            <Divider orientation="vertical" flexItem />
            <ListItem className={classes.listItemForSelection} disablePadding>
              <ListItemAvatar sx={{ minWidth: "40px", ml: "-13px" }}>
                <Checkbox
                  checked={previousPeriod.isChecked}
                  onChange={(e) =>
                    setPreviousPeriod({
                      ...previousPeriod,
                      isChecked: e.target.checked,
                    })
                  }
                />
              </ListItemAvatar>
              <ListItemText
                primary="Compare to previous period:"
                secondary={handleCalculatePreviousLabel()}
              />
            </ListItem>
          </Stack>
          <Divider />
          {chartData.isLoading ? (
            <Box sx={{ height: "220px" }}>
              <Skeleton
                animation="wave"
                variant="rounded"
                width={"100%"}
                height="100%"
              />
            </Box>
          ) : (
            <Box>
              <AgentActivityChart
                customOptions={customOptions}
                data={handleMakeChartData()}
                {...props}
              />
              <Box className={classes.graphBottomDates}>
                <Typography>
                  {moment(
                    previousPeriod.isChecked
                      ? previousPeriod.fromDate
                      : filters.fromDate
                  )
                    .tz("America/New_York")
                    .format("ll")}
                </Typography>
                <Typography>
                  {moment(
                    previousPeriod.isChecked
                      ? previousPeriod.lastDate
                      : filters.lastDate
                  )
                    .tz("America/New_York")
                    .format("ll")}
                </Typography>
              </Box>
            </Box>
          )}
        </Card>
        <br />

        {agentProfitability.isLoading ? (
          <ActivityStatsSkelton height={"70px"} width={"270px"} />
        ) : (
          <Box className={classes.agentActivitiesStatsArea}>
            {agentProfitability.stats
              ? Object.keys(agentProfitability.stats)?.map((key, index) => (
                  <Card
                    variant="outlined"
                    key={index}
                    className={classes.agentActivitiesStatsItem}
                  >
                    <Box className={classes.agentActivitiesStatsItemTop}>
                      <Box className={classes.agentActivitiesStatContent}>
                        {key?.replace(/([a-z])([A-Z])/g, "$1 $2")}
                      </Box>
                      <Box className={classes.agentActivitiesStatValue}>
                        {!agentProfitability?.stats[key]
                          ? "0"
                          : key === "totalClosings" || key === "totalLeads"
                          ? NumberFormat({
                              number: agentProfitability?.stats[key] || 0,
                              maximumFractionDigits: 0,
                            })
                          : NumberFormat({
                              number: agentProfitability?.stats[key] || 0,
                              maximumFractionDigits: 0,
                              currency: "USD",
                            })}
                      </Box>
                    </Box>
                  </Card>
                ))
              : null}
          </Box>
        )}
        <br />
        <ActivityRecordsTable
          loading={agentProfitability.isLoading}
          data={
            agentProfitability?.data?.length
              ? agentProfitability?.data?.map((it) => ({
                  ...it,
                  id: it.agent_id,
                }))
              : []
          }
          filters={filters}
          userList={storedListOfUsers?.list}
          {...props}
        />
      </Container>
    </Box>
  );
}
export default withStyles(styleSheet, { name: "UserReportingStyle" })(
  UserReporting
);
