import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const getTransactions = createAsyncThunk(
  "ProfitReport/getTransactions",
  async (data, thunkAPI) => {
    try {
      const resp = await axios.get(
        `${
          process.env.REACT_APP_BASE_URL
        }api/users/profitability/transactions?start_date=${
          data.start_date
        }&end_date=${data.end_date}&team_id=${data.team_id || ""}&agent_ids=${
          data?.agent_ids
        }&pageNumber=${data.pageNumber}&pageSize=20`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return { ...resp.data, pageNumber: data.pageNumber };
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(
          error.response.data.errorMessage || error.response.data.message
        );
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);
