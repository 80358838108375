import React, { useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import {
  Button,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemAvatar,
  OutlinedInput,
  Divider,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SearchIcon from "@mui/icons-material/Search";
import { useSelector } from "react-redux";
import Avatar from "../avatar";
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    PaperProps={{
      elevation: 0,
      sx: {
        overflow: "visible",
        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
        mt: 1.5,
        "&:before": {
          content: '""',
          display: "block",
          position: "absolute",
          top: 0,
          right: 14,
          width: 10,
          height: 10,
          bgcolor: "background.paper",
          transform: "translateY(-50%) rotate(45deg)",
          zIndex: 0,
        },
      },
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 200,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

function UserSelectionsForStats(props) {
  let { classes, handleGetUserDetail, defaultValue } = props;
  const [searchText, setSearchText] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const open = Boolean(anchorEl);
  const storedListOfUsers = useSelector((item) => item.users.storedListOfUsers);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const filteredList = (userList) => {
    if (searchText) {
      return userList.filter((item) =>
        item.full_name
          .toLowerCase()
          ?.slice(0, searchText.length)
          ?.includes(searchText)
      );
    } else {
      return userList;
    }
  };
  const CUstomSortUsers = (users) => {
    let agents = users.filter((item) => item?.role?.name === "Agents");
    let admins = users.filter((item) => item?.role?.name === "Admin");
    let teamLeader = users.filter((item) => item?.role?.name === "Team Leader");
    let otherUsers = users.filter(
      (item) =>
        item?.role?.name !== "Team Leader" &&
        item?.role?.name !== "Admin" &&
        item?.role?.name !== "Agents"
    );
    return [
      ...teamLeader,
      ...admins,
      ...agents,
      ...otherUsers?.filter((item) => item.group !== undefined),
    ];
  };
  const handleSelectUser = (user) => {
    handleGetUserDetail(user);
    handleClose();
    if (user === "everyOne") {
      setSelectedUser("everyOne");
    } else {
      setSelectedUser(user);
    }
  };

  return (
    <div>
      <Button
        id="customer-date-picker-dropdown"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        className={classes.dateDropdownButtonStyle}
        disableElevation
        onClick={handleClick}
        endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      >
        {selectedUser?.full_name
          ? selectedUser.full_name
          : selectedUser === "everyOne"
          ? "Everyone"
          : defaultValue}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Box sx={{ height: "320px", overflowY: "auto" }}>
          <Box sx={{ padding: "10px" }}>
            <OutlinedInput
              value={searchText}
              onChange={(e) => setSearchText(e.target.value.toLowerCase())}
              fullWidth
              size="small"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton edge="end">
                    <SearchIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              }
              name="reporting-user-selecting"
              placeholder="Search user"
            />
          </Box>
          <Divider />
          <List
            dense
            sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          >
            <ListItem
              onClick={() => handleSelectUser("everyOne")}
              disablePadding
            >
              <ListItemButton>
                <ListItemText primary={`Everyone`} />
              </ListItemButton>
            </ListItem>
            {CUstomSortUsers(filteredList(storedListOfUsers.list)).map(
              (item, value) => {
                const labelId = `checkbox-list-secondary-label-${value}`;
                return (
                  <ListItem
                    onClick={() => handleSelectUser(item)}
                    key={value}
                    disablePadding
                  >
                    <ListItemButton>
                      <ListItemAvatar>
                        <Avatar
                          sx={{
                            width: "30px",
                            height: "30px",
                            fontSize: "13px",
                          }}
                          src={item?.profile_images?.profile_img}
                        >
                          {item.first_name?.slice(0, 1)}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        id={labelId}
                        primary={`${item.first_name} ${item.last_name}`}
                      />
                    </ListItemButton>
                  </ListItem>
                );
              }
            )}
          </List>
        </Box>
      </StyledMenu>
    </div>
  );
}
export default withStyles(styleSheet, { name: "UserSelectionsForStatsStyle1" })(
  UserSelectionsForStats
);
