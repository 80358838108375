import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const sendOnboardingEmail = createAsyncThunk(
  "agentProfile/sendOnboardingEmail",
  async (data, thunkAPI) => {
    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/users/send-onboarding-email/${data.userId}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (data?.handleSuccess) {
        data.handleSuccess();
      }
      return resp.data.data;
    } catch (error) {
      if (error.response) {
        data.handleError(
          error.response.data.errorMessage || error.response.data.message
        );
        return thunkAPI.rejectWithValue(
          error.response.data.errorMessage || error.response.data.message
        );
      } else {
        data.handleError(error.message);
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);
