import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  Skeleton,
} from "@mui/material";
import Avatar from "../../../components/uiElements/avatar";
import { useDispatch, useSelector } from "react-redux";
import { getStaffUsers, getSpecificStaff } from "../../../redux/staff";
function StaffUsers(props) {
  const { classes, setSelectedUser, selectedUser, setManageType } = props;
  const dispatch = useDispatch();

  const staffUsers = useSelector((item) => item.Staff.staffUsers);

  const handleGetStaffUser = (pageNumber) => {
    if (!staffUsers?.isLoading) {
      dispatch(getStaffUsers({ pageNumber: pageNumber || 1 }));
    }
  };

  useEffect(() => {
    handleGetStaffUser();
  }, []);
  const handleScrollEvent = (e) => {
    let bottom =
      e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight <= 0;
    if (bottom) {
      if (staffUsers?.data?.length < staffUsers.count) {
        let currentPageNumber = Math.ceil(staffUsers?.data?.length / 25);
        handleGetStaffUser(currentPageNumber + 1);
      }
    }
  };

  return (
    <Box className={classes.staffUsersSection}>
      <Box className={classes.staffUserHeader}>
        <Typography className={classes.staffUserHeading}>
          Staff MEMBERS
        </Typography>
      </Box>
      <List
        onScroll={handleScrollEvent}
        className={classes.staffUserList}
        dense
      >
        {staffUsers?.data?.map((item) => {
          const labelId = `checkbox-list-secondary-label-${item.id}`;
          return (
            <ListItem
              onClick={() => {
                setSelectedUser(item);
                setManageType("user");
                dispatch(getSpecificStaff({ id: item.id }));
              }}
              key={item.id}
              disablePadding
            >
              <ListItemButton selected={item.id === selectedUser?.id}>
                <ListItemAvatar sx={{ minWidth: "45px" }}>
                  <Avatar
                    alt={`Avatar ${item?.first_name}`}
                    src={item?.profile_images?.profile_img_thumbnail}
                    sx={{ width: "30px", height: "30px", fontSize: "14px" }}
                    variant="rounded"
                  >
                    {`${item?.first_name?.slice(0, 1)}${item?.last_name?.slice(
                      0,
                      1
                    )}`}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText id={labelId} primary={item?.full_name} />
              </ListItemButton>
            </ListItem>
          );
        })}

        {staffUsers?.isLoading
          ? Array.from(Array(15).keys())?.map((_, index) => (
              <ListItem key={index} disablePadding>
                <ListItemButton>
                  <ListItemAvatar sx={{ minWidth: "45px" }}>
                    <Skeleton
                      variant="rectangular"
                      sx={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "5px",
                      }}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                    }
                  />
                </ListItemButton>
              </ListItem>
            ))
          : null}
      </List>
    </Box>
  );
}

export default StaffUsers;
