export const styleSheet = (theme, props) => ({
  dateDropdownButtonStyle: {
    border: "1px solid rgb(215, 222, 226) !important",
    borderRadius: "4px",
    background: "linear-gradient(rgb(255, 255, 255), rgb(232, 236, 240))",
    color: "rgb(109, 130, 145) !important",
    fontSize: "14px !important",
    fontWeight: "500 !important",
    fontFamily: "AvenirNext  !important",
    textTransform: "capitalize  !important",
  },
  dateDropdownButtonStyleContent: {
    maxWidth: "250px !important",
    whiteSpace: "noWrap !important",
    overflow: "hidden !important",
    textOverflow: "ellipsis !important",
  },
  dateDropdownItem: {
    borderBottom: "1px solid lightgrey !important",
    fontSize: "14px !important",
    "& 	.MuiInputBase-input": {
      width: "141px !important",
      fontSize: "13px !important",
      paddingLeft: "8px !important",
    },
  },
  customDateInput: {
    width: "141px !important",
  },
  SearchButtonSection: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
    gap: "12px !important",
    padding: "10px !important",
  },
  applyButton: {
    width: "90px !important",
    background: "black !important",
    color: "white !important",
    fontSize: "14px !important",
    textTransform: "capitalize !important",
  },
});
