import React, { useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import { Stack, Box } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const moment = require("moment-timezone");
const dropdownMenu = [
  {
    title: "Custom Date",
    value: "custom",
  },
  {
    title: "Today",
    value: "today",
  },
  {
    title: "Yesterday",
    value: "yesterday",
  },
  {
    title: "This Week (Mon-Sun)",
    value: "currentWeek",
  },
  {
    title: "Last 7 days",
    value: 7,
    isLast: true,
  },
  {
    title: "Last Week (Mon-Sun)",
    value: "lastWeek",
  },
  {
    title: "Last 14 days",
    value: 14,
    isLast: true,
  },
  {
    title: "This Month",
    value: "currentMonth",
  },
  {
    title: "Last 30 days",
    value: 30,
    isLast: true,
  },
  {
    title: "Last Month",
    value: "lastMonth",
  },
  {
    title: "Last 90 days",
    value: 90,
    isLast: true,
  },
  {
    title: "This Year",
    value: "currentYear",
  },
  {
    title: "Last Year",
    value: "lastYear",
  },
  {
    title: "All Time",
    value: "allTime",
  },
];

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    PaperProps={{
      elevation: 0,
      sx: {
        overflow: "visible",
        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
        mt: 1.5,
        "&:before": {
          content: '""',
          display: "block",
          position: "absolute",
          top: 0,
          right: 14,
          width: 10,
          height: 10,
          bgcolor: "background.paper",
          transform: "translateY(-50%) rotate(45deg)",
          zIndex: 0,
        },
      },
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 240,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

function UserSelectionsForStats(props) {
  let { classes, handleGetDates, defaultValue, customOption } = props;
  const [isCustomDate, setIsCustomDate] = useState(false);
  const [displayTitle, setDisplayTitle] = useState(
    localStorage?.filters ? localStorage?.dateTitle : ""
  );
  const [customDates, setCustomDates] = useState({
    fromDate: moment()
      .tz("America/New_York")
      .subtract(customOption ? 364 : 7, "day")
      .format(),
    lastDate: moment().tz("America/New_York").format(),
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleGetFromAndLastDate = (data) => {
    if (data.isLast) {
      let obj = {
        fromDate: moment()
          .tz("America/New_York")
          .subtract(data.value, "day")
          .format(),
        lastDate: moment().tz("America/New_York").format(),
      };
      return obj;
    } else if (data.value === "today") {
      let obj = {
        fromDate: moment().tz("America/New_York").startOf("day").format(),
        lastDate: moment().tz("America/New_York").endOf("day").format(),
      };
      return obj;
    } else if (data.value === "yesterday") {
      let obj = {
        fromDate: moment()
          .tz("America/New_York")
          .subtract(1, "day")
          .startOf("day")
          .format(),
        lastDate: moment()
          .tz("America/New_York")
          .subtract(1, "day")
          .endOf("day")
          .format(),
      };
      return obj;
    } else if (data.value === "currentWeek") {
      let obj = {
        fromDate: moment()
          .tz("America/New_York")
          .startOf("week")
          .weekday(1)
          .format(),
        lastDate: moment()
          .tz("America/New_York")
          .endOf("week")
          .isoWeekday("Sunday")
          .format(),
      };
      return obj;
    } else if (data.value === "lastWeek") {
      let obj = {
        fromDate: moment()
          .tz("America/New_York")
          .subtract(1, "week")
          .startOf("week")
          .weekday(1)
          .format(),
        lastDate: moment()
          .tz("America/New_York")
          .subtract(1, "week")
          .endOf("week")
          .isoWeekday("Sunday")
          .format(),
      };
      return obj;
    } else if (data.value === "currentMonth") {
      let obj = {
        fromDate: moment().tz("America/New_York").startOf("month").format(),
        lastDate: moment().tz("America/New_York").endOf("month").format(),
      };
      return obj;
    } else if (data.value === "lastMonth") {
      let obj = {
        fromDate: moment()
          .tz("America/New_York")
          .subtract(1, "month")
          .startOf("month")
          .format(),
        lastDate: moment()
          .tz("America/New_York")
          .subtract(1, "month")
          .endOf("month")
          .format(),
      };
      return obj;
    } else if (data.value === "currentYear") {
      let obj = {
        fromDate: moment().tz("America/New_York").startOf("year").format(),
        lastDate: moment().tz("America/New_York").endOf("year").format(),
      };
      return obj;
    } else if (data.value === "lastYear") {
      let obj = {
        fromDate: moment()
          .tz("America/New_York")
          .subtract(1, "year")
          .startOf("year")
          .format(),
        lastDate: moment()
          .tz("America/New_York")
          .subtract(1, "year")
          .endOf("year")
          .format(),
      };
      return obj;
    } else if (data.value === "last2Year") {
      let obj = {
        fromDate: moment()
          .tz("America/New_York")
          .subtract(2, "year")
          .startOf("year")
          .format(),
        lastDate: moment()
          .tz("America/New_York")
          .subtract(1, "year")
          .endOf("year")
          .format(),
      };
      return obj;
    } else if (data.value === "last3Year") {
      let obj = {
        fromDate: moment()
          .tz("America/New_York")
          .subtract(3, "year")
          .startOf("year")
          .format(),
        lastDate: moment()
          .tz("America/New_York")
          .subtract(1, "year")
          .endOf("year")
          .format(),
      };
      return obj;
    } else if (data.value === "last4Year") {
      let obj = {
        fromDate: moment()
          .tz("America/New_York")
          .subtract(4, "year")
          .startOf("year")
          .format(),
        lastDate: moment()
          .tz("America/New_York")
          .subtract(1, "year")
          .endOf("year")
          .format(),
      };
      return obj;
    } else if (data.value === "last5Year") {
      let obj = {
        fromDate: moment()
          .tz("America/New_York")
          .subtract(5, "year")
          .startOf("year")
          .format(),
        lastDate: moment()
          .tz("America/New_York")
          .subtract(1, "year")
          .endOf("year")
          .format(),
      };
      return obj;
    } else if (data.value === "last10Year") {
      let obj = {
        fromDate: moment()
          .tz("America/New_York")
          .subtract(10, "year")
          .startOf("year")
          .format(),
        lastDate: moment()
          .tz("America/New_York")
          .subtract(1, "year")
          .endOf("year")
          .format(),
      };
      return obj;
    } else if (data.value === "allTime") {
      let obj = {
        fromDate: moment("2012").tz("America/New_York").format(),
        lastDate: moment().tz("America/New_York").format(),
      };
      return obj;
    }
  };
  const handleSelectMenu = (data) => {
    if (data.value === "custom") {
      setIsCustomDate(true);
    } else {
      setDisplayTitle(data.title);
      localStorage.setItem("dateTitle", data.title);
      handleGetDates(handleGetFromAndLastDate(data));
      setIsCustomDate(false);
      handleClose();
    }
  };
  const handleApplyCustomDate = () => {
    handleGetDates(customDates);
    handleClose();
  };
  return (
    <div>
      <Button
        id="customer-date-picker-dropdown"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        className={classes.dateDropdownButtonStyle}
        disableElevation
        onClick={handleClick}
        endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      >
        {isCustomDate
          ? `${moment(customDates.fromDate).format("L")}-${moment(
              customDates.lastDate
            ).format("L")}`
          : displayTitle || defaultValue}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Box sx={{ height: customOption ? "auto" : "320px", overflow: "auto" }}>
          {(customOption ? [...customOption] : dropdownMenu).map(
            (item, index) => (
              <MenuItem
                className={classes.dateDropdownItem}
                key={index}
                onClick={() => handleSelectMenu(item)}
                disableRipple
              >
                {isCustomDate && item.value === "custom" ? (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack
                      spacing={1}
                      direction={"row"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <DatePicker
                        value={customDates.fromDate}
                        onChange={(newValue) => {
                          setCustomDates({
                            ...customDates,
                            fromDate: new Date(newValue).toISOString(),
                          });
                        }}
                        disableFuture
                        renderInput={(params) => (
                          <TextField
                            classes={{ root: classes.customDateInput }}
                            size="small"
                            {...params}
                          />
                        )}
                      />
                      <Box>to</Box>
                      <DatePicker
                        value={customDates.lastDate}
                        onChange={(newValue) => {
                          setCustomDates({
                            ...customDates,
                            lastDate: new Date(newValue).toISOString(),
                          });
                        }}
                        disableFuture
                        renderInput={(params) => (
                          <TextField
                            classes={{ root: classes.customDateInput }}
                            size="small"
                            {...params}
                          />
                        )}
                      />
                      <Button
                        onClick={handleApplyCustomDate}
                        size="small"
                        variant="contained"
                        className={classes.dateDropdownButtonStyle}
                      >
                        Apply
                      </Button>
                    </Stack>
                  </LocalizationProvider>
                ) : (
                  item.title
                )}
              </MenuItem>
            )
          )}
        </Box>
      </StyledMenu>
    </div>
  );
}
export default withStyles(styleSheet, { name: "UserSelectionsForStatsStyle1" })(
  UserSelectionsForStats
);
