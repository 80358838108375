import React, { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, CircularProgress, Typography, Card, Autocomplete, TextField, Stack, MenuItem, IconButton } from "@mui/material";
import Grid from "@mui/material/Grid";
import ProcessedDetailSkelton from "../skelton/processedDetail";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import AlternateEmailRoundedIcon from "@mui/icons-material/AlternateEmailRounded";
import { withStyles } from "@mui/styles";
import { styleSheet } from "../style.js";
import { approveUnprocessedClosings, sendForPreviewUnprocessedClosings } from "../../../redux/finance/approveUnprocessedClosings";
import { getClosingInfo, setClosingInfoToNull } from "../../../redux/finance/getClosingInfo.js";
import EditContract from "../common/EditContract.jsx";
import moment from "moment";
import { getCommissionPlans } from "../../../redux/finance/commissionPlans.js";
import { getSignedUrl } from "../../../redux/common/getSignedUrl.js";
import ConfirmModal from "../../../components/widgets/modals/ConfirmModal";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ResponseAlert from "../../../components/responseAlert";
import { getParticularContract } from "../../../redux/transactions";
// import { getSignedUrl } from "../../../redux/common/getSignedUrl";

function FinanceFile({ classes, isContract, closeCommissionBreakageModal }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [openPreview, setOpenPreview] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState();
  const Fragment = React.Fragment;
  const location = useLocation();
  const [editContract, setEditContract] = React.useState(false);
  const [grossCommisions, setGrossCommisions] = useState([]);
  const [companyDollars, setCompanyDollars] = useState([]);
  const [agentSplit, setAgentSplit] = useState([]);
  const [rule_id, setRuleId] = useState(null);
  const [reloadCalculation, setReloadCalculation] = useState(false);
  const [companyDollarsDeductions, setCompanyDollarsDeductions] = useState([]);
  const [agentSplitDeductions, setAgentSplitDeductions] = useState([]);
  const [grossCommisionsDeductions, setGrossCommisionsDeductions] = useState([]);
  const [confirmApprove, setConfirmApprove] = useState(false);
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const [companyDollarsAmounts, setCompanyDollarsAmounts] = useState({
    gross: 0,
    net: 0,
  });
  const [agentSplitAmounts, setAgentSplitAmounts] = useState({
    gross: 0,
    net: 0,
  });
  const [grossCommisionsAmounts, setGrossCommisionsAmounts] = useState({
    gross: 0,
    net: 0,
  });

  const [assignedAgentSplitValue, setAssignedAgentSplitValue] = useState(0);
  const [assignedAgentSplitType, setAssignedAgentSplitType] = useState("%");

  const ContractData = useSelector((state) => state.transactions.TransactionsContract.ContractData?.data);

  useEffect(() => {
    if (location.pathname.startsWith("/finance/closings/processed/")) {
      setOpenPreview(true);
      if (!isContract) {
        dispatch(getClosingInfo({ id, processed: true }));
      }
    } else {
      if (!isContract) {
        dispatch(getClosingInfo({ id, processed: false }));
      }
      dispatch(getCommissionPlans({ pageNumber: 1, pageSize: 10000 }));
    }
  }, [id]);

  const { closing: closingData, actionLoading, loading } = useSelector((state) => state.finance.closingInfo);
  useEffect(() => {
    let gciDeduction = [],
      agentDeduction = [],
      companyDeduction = [];
    let gciCommission = isContract ? Math.abs(grossCommisionsAmounts.gross) : Math.abs(closingData.gci),
      contractPrice = isContract ? Math.abs(ContractData.price) : Math.abs(closingData.price),
      grossGci = gciCommission;

    for (let gci of grossCommisions) {
      if (gci.value && gci.paymentType && gci.acctCode && (gci.payTo || gci.payTo === 0)) {
        if (gci.paymentType === "%") {
          let value = Math.abs(gci.value);
          let deduction = 0;
          if (gci.percentageOf === "gci") {
            deduction = Math.abs(((gciCommission * value) / 100).toFixed(2));
            gciCommission -= deduction;
          } else if (gci.percentageOf === "contractPrice") {
            deduction = Math.abs(((contractPrice * value) / 100).toFixed(2));
            gciCommission -= deduction;
            contractPrice -= deduction;
          }
          if (deduction) {
            gciDeduction.push(deduction);
          }
        } else {
          let value = Math.abs(gci.value);
          let deduction = Math.abs(value.toFixed(2));
          gciCommission -= deduction;
          if (deduction) {
            gciDeduction.push(deduction);
          }
        }
      }
    }

    let agentCommission = Math.abs(assignedAgentSplitValue),
      companyDollarAmount = gciCommission - agentCommission;
    if (assignedAgentSplitType === "%") {
      agentCommission = Math.abs(((gciCommission * Math.abs(assignedAgentSplitValue)) / 100).toFixed(2));
      companyDollarAmount = gciCommission - agentCommission;
    }
    let grossAgentCommission = agentCommission;
    let grossCompanyDollar = companyDollarAmount;
    for (let agent of agentSplit) {
      if (agent.value && agent.paymentType && agent.acctCode && (agent.payTo || agent.payTo === 0)) {
        if (agent.paymentType === "%") {
          let value = Math.abs(agent.value);
          let deduction = 0;
          if (agent.percentageOf === "gci") {
            deduction = Math.abs(((gciCommission * value) / 100).toFixed(2));
            agentCommission -= deduction;
          } else if (agent.percentageOf === "contractPrice") {
            deduction = Math.abs(((contractPrice * value) / 100).toFixed(2));
            agentCommission -= deduction;
            contractPrice -= deduction;
          }
          if (deduction) {
            agentDeduction.push(deduction);
          }
        } else {
          let value = Math.abs(agent.value);
          let deduction = Math.abs(value.toFixed(2));
          agentCommission -= deduction;
          if (deduction) {
            agentDeduction.push(deduction);
          }
        }
      }
    }

    for (let company of companyDollars) {
      if (company.value && company.paymentType && company.acctCode && (company.payTo || company.payTo === 0)) {
        if (company.paymentType === "%") {
          let value = Math.abs(company.value);
          let deduction = 0;
          if (company.percentageOf === "gci") {
            deduction = Math.abs(((gciCommission * value) / 100).toFixed(2));
            companyDollarAmount -= deduction;
          } else if (company.percentageOf === "contractPrice") {
            deduction = Math.abs(((contractPrice * value) / 100).toFixed(2));
            companyDollarAmount -= deduction;
            contractPrice -= deduction;
          }
          if (deduction) {
            companyDeduction.push(deduction);
          }
        } else {
          let value = Math.abs(company.value);
          let deduction = Math.abs(value.toFixed(2));
          companyDollarAmount -= deduction;
          if (deduction) {
            companyDeduction.push(deduction);
          }
        }
      }
    }
    setCompanyDollarsDeductions(companyDeduction);
    setAgentSplitDeductions(agentDeduction);
    setGrossCommisionsDeductions(gciDeduction);

    setCompanyDollarsAmounts({
      gross: grossCompanyDollar || 0,
      net: companyDollarAmount || 0,
    });
    setAgentSplitAmounts({
      gross: grossAgentCommission || 0,
      net: agentCommission || 0,
    });
    setGrossCommisionsAmounts({
      gross: grossGci || 0,
      net: gciCommission || 0,
    });
  }, [closingData, grossCommisions, agentSplit, companyDollars, assignedAgentSplitValue, assignedAgentSplitType, ContractData, reloadCalculation]);

  useEffect(() => {
    return () => {
      dispatch(setClosingInfoToNull({}));
    };
  }, []);
  const commissionPlans = useSelector((state) => state.finance.commissionPlans);
  const { data } = commissionPlans;

  const { vendorList, vendorsObj } = useSelector((item) => item.users.storedListOfUsers);
  useEffect(() => {
    let closingDetails = isContract ? { ...ContractData, rule: ContractData.rules } : closingData;
    if (closingDetails && closingDetails.rule && vendorsObj) {
      let { gci, agent_split, company_dollar, gciDeduct, agent_splitDeduct, company_dollarDeduct } = closingDetails.rule.conditions.reduce(
        (prev, curr) => {
          if (!curr.user_id && curr.payTo) {
            if (vendorsObj[curr.payTo]) {
              curr = {
                ...curr,
                user_id: vendorsObj[curr.payTo].user_id,
                tax_id: vendorsObj[curr.payTo].tax_id,
              };
            }
          }
          prev[curr.type].push(curr);
          prev[curr.type + "Deduct"].push(curr.amountDeducted);
          return prev;
        },
        {
          gci: [],
          agent_split: [],
          company_dollar: [],
          gciDeduct: [],
          agent_splitDeduct: [],
          company_dollarDeduct: [],
        }
      );
      setRuleId(closingDetails.rule.id);
      setGrossCommisions(gci);
      setCompanyDollars(company_dollar);
      setAgentSplit(agent_split);

      setCompanyDollarsDeductions(gciDeduct);
      setAgentSplitDeductions(agent_splitDeduct);
      setGrossCommisionsDeductions(company_dollarDeduct);

      setCompanyDollarsAmounts({
        gross: closingDetails.rule.company_gross,
        net: closingDetails.rule.company_net,
      });
      setAgentSplitAmounts({
        gross: closingDetails.rule.agent_gross,
        net: closingDetails.rule.agent_net,
      });
      setGrossCommisionsAmounts({
        gross: closingDetails.rule.gci_gross,
        net: closingDetails.rule.gci_net,
      });
      setAssignedAgentSplitValue(closingDetails.rule.agent_value);
      setAssignedAgentSplitType(closingDetails.rule.agent_amount_type);
      setSelectedTemplate(data.find((c) => c.id == closingDetails.rule.commission_template_id));
    }
  }, [closingData, ContractData]);

  const openPdf = (src) => {
    dispatch(getSignedUrl(src)).then((result) => {
      window.open(result.payload.data);
    });
  };

  const renderTitle = (amount, title, bold = false) => {
    return (
      <Grid container className={classes.mainSectionTitle + " " + (bold ? classes.commissionBold : null)}>
        <Grid item xs={3.2}>
          <Typography variant="h3">{formatter.format(amount)}</Typography>
        </Grid>
        <Grid xs={8.5}>
          <Typography variant="h4">{!title.insert ? title : title.insert}</Typography>
        </Grid>
      </Grid>
    );
  };

  const renderContent = (content, deductions) => {
    const render = (object, amount) => {
      return (
        <Grid container className={classes.mainSectionContent}>
          <Grid item xs={2.2}>
            <Typography variant="h5">
              {amount ? formatter.format(amount) : object.amountDeducted ? formatter.format(object.amountDeducted) : "N/A"}
            </Typography>
          </Grid>
          <Grid item xs={0.9}>
            -------
          </Grid>
          <Grid xs={8.5}>
            <Typography variant="h5">{object.name ? `To ${object.name} ${object.via ? `via ${object.via}` : ""} ` : "N/A"}</Typography>
          </Grid>
        </Grid>
      );
    };

    if (!content || content.length < 1) {
      return;
    }

    if (Array.isArray(content)) {
      return content.map((element, i) => {
        return render(element, deductions ? deductions[i] : null);
      });
    }
    return render(content);
  };

  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const handleApprove = async () => {
    setConfirmApprove(true);
  };

  const handleConfirm = async () => {
    let data = {
      selectedTemplateId: selectedTemplate ? selectedTemplate.id : null,
      companyDollarsAmounts,
      agentSplitAmounts,
      grossCommisionsAmounts,
      rule_id: rule_id,
      companySplitValue: assignedAgentSplitType === "%" ? 100 - assignedAgentSplitValue : grossCommisionsAmounts.net - assignedAgentSplitValue,
      assignedAgentSplitValue: assignedAgentSplitValue,
      assignedAgentSplitType: assignedAgentSplitType,
      gci: grossCommisions.map((gc, i) => {
        return { ...gc, amountDeducted: grossCommisionsDeductions[i] };
      }),
      agent_split: agentSplit?.map((agent, i) => {
        return { ...agent, amountDeducted: agentSplitDeductions[i] };
      }),
      company_dollars: companyDollars.map((company, i) => {
        return { ...company, amountDeducted: companyDollarsDeductions[i] };
      }),
    };
    if (!isContract) {
      dispatch(approveUnprocessedClosings({ id, data })).then((result) => {
        if (result.payload.status === 200) {
          setConfirmApprove(false);
          navigate("/finance/closings/unprocessed");
        }
      });
    }
  };

  const saveRule = async () => {
    let data = {
      selectedTemplateId: selectedTemplate ? selectedTemplate.id : null,
      companyDollarsAmounts,
      agentSplitAmounts,
      grossCommisionsAmounts,
      rule_id: rule_id,
      companySplitValue: assignedAgentSplitType === "%" ? 100 - assignedAgentSplitValue : grossCommisionsAmounts.net - assignedAgentSplitValue,
      assignedAgentSplitValue: assignedAgentSplitValue,
      assignedAgentSplitType: assignedAgentSplitType,
      gci: grossCommisions.map((gc, i) => {
        return { ...gc, amountDeducted: grossCommisionsDeductions[i] };
      }),
      agent_split: agentSplit?.map((agent, i) => {
        return { ...agent, amountDeducted: agentSplitDeductions[i] };
      }),
      company_dollars: companyDollars.map((company, i) => {
        return { ...company, amountDeducted: companyDollarsDeductions[i] };
      }),
      need_to_send_notification: true,
    };
    if (id) {
      dispatch(sendForPreviewUnprocessedClosings({ id, data })).then((result) => {
        setRuleId(result?.payload?.data?.id);
      });
    }
  };

  const saveRuleWithoutNotification = async () => {
    let data = {
      selectedTemplateId: selectedTemplate ? selectedTemplate.id : null,
      companyDollarsAmounts,
      agentSplitAmounts,
      grossCommisionsAmounts,
      status: isContract ? ContractData.status : null,
      payment_status: isContract ? "Preview" : null,
      rule_id: rule_id,
      companySplitValue: assignedAgentSplitType === "%" ? 100 - assignedAgentSplitValue : grossCommisionsAmounts.net - assignedAgentSplitValue,
      assignedAgentSplitValue: assignedAgentSplitValue,
      assignedAgentSplitType: assignedAgentSplitType,
      gci: grossCommisions.map((gc, i) => {
        return { ...gc, amountDeducted: grossCommisionsDeductions[i] };
      }),
      agent_split: agentSplit?.map((agent, i) => {
        return { ...agent, amountDeducted: agentSplitDeductions[i] };
      }),
      company_dollars: companyDollars.map((company, i) => {
        return { ...company, amountDeducted: companyDollarsDeductions[i] };
      }),
    };
    if (id) {
      dispatch(sendForPreviewUnprocessedClosings({ id, data })).then((result) => {
        if (isContract) {
          setErrorAlert({
            errorMsg: "Saved successfully!",
            errorType: "success",
            isOpen: true,
          });
          dispatch(getParticularContract({ id }));

          closeCommissionBreakageModal();
        }
        setRuleId(result?.payload?.data?.id);
      });
    }
  };

  const handleValidateRow = (obj) => {
    let isFormValid = true;
    console.log(obj);
    if (obj) {
      Object.keys(obj).forEach((k) => {
        if (k === "percentageOf" && obj["paymentType"] === "$" && obj["percentageOf"] === "") {
          console.log("sdcdscds");
          
          return;
        } else if (obj[k] === "") {
          isFormValid = false;
        }
      });
    }
    return isFormValid;
  };

  const handleGrossDeduction = () => {
    let commisions = [...grossCommisions];
    let valObj = commisions.slice(-1)[0];

    if (valObj && valObj.paymentType === "$") {
      if (valObj.percentageOf) {
        delete valObj.percentageOf;
      }
    }
    if (handleValidateRow(valObj)) {
      commisions.push({
        value: "",
        paymentType: "%",
        acctCode: "teamLeaderComm",
        percentageOf: "",
        payTo: "",
        via: "",
        name: "",
        user_id: "",
        tax_id: "",
      });
      setGrossCommisions(commisions);
    } else {
      setErrorAlert({
        errorMsg: "Pls fill  all fields first!",
        errorType: "warning",
        isOpen: true,
      });
    }
  };

  const handleDeleteGrossDeduction = (index) => {
    let commisions = [...grossCommisions];
    commisions.splice(index, 1);
    setGrossCommisions(commisions);
  };

  const handleAddAgentSplit = () => {
    let commisions = [...agentSplit];
    let valObj = commisions.slice(-1)[0];

    if (valObj && valObj.paymentType === "$") {
      if (valObj.percentageOf) {
        delete valObj.percentageOf;
      }
    }
    if (handleValidateRow(valObj)) {
      commisions.push({
        value: "",
        paymentType: "%",
        acctCode: "teamLeaderComm",
        percentageOf: "",
        payTo: "",
        via: "",
        name: "",
        user_id: "",
      });
      setAgentSplit(commisions);
    } else {
      setErrorAlert({
        errorMsg: "Pls fill  all fields first!",
        errorType: "warning",
        isOpen: true,
      });
    }
  };
  const handleDeleteAgentSplit = (index) => {
    let commisions = [...agentSplit];
    commisions.splice(index, 1);
    setAgentSplit(commisions);
  };

  const handleAddCompanyDollars = () => {
    let commisions = [...companyDollars];
    let valObj = commisions.slice(-1)[0];

    if (valObj && valObj.paymentType === "$") {
      if (valObj.percentageOf) {
        delete valObj.percentageOf;
      }
    }
    if (handleValidateRow(valObj)) {
      commisions.push({
        value: "",
        paymentType: "%",
        acctCode: "teamLeaderComm",
        percentageOf: "",
        payTo: "",
        via: "",
        name: "",
        user_id: "",
      });
      setCompanyDollars(commisions);
    } else {
      setErrorAlert({
        errorMsg: "Pls fill  all fields first!",
        errorType: "warning",
        isOpen: true,
      });
    }
  };
  const handleDeleteCompanyDollarsRow = (index) => {
    let commisions = [...companyDollars];
    commisions.splice(index, 1);
    setCompanyDollars(commisions);
  };

  return (
    <Box className={isContract ? "" : classes.financeFile}>
      {loading ? (
        <ProcessedDetailSkelton isContract={isContract} />
      ) : (
        <Box>
          {isContract ? null : (
            <Card variant="outlined">
              <Grid className={classes.headerCommission} container spacing={0}>
                <Box className={classes.headerIcons}>
                  <PhoneRoundedIcon />
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M6.527 14.514A1.973 1.973 0 0 1 4.56 16.48a1.973 1.973 0 0 1-1.967-1.967c0-1.083.884-1.968 1.967-1.968h1.968v1.968zm.992 0c0-1.083.884-1.968 1.967-1.968 1.083 0 1.968.885 1.968 1.968v4.927a1.973 1.973 0 0 1-1.968 1.967 1.973 1.973 0 0 1-1.967-1.967v-4.927zm1.967-7.987A1.973 1.973 0 0 1 7.52 4.56c0-1.083.884-1.967 1.967-1.967 1.083 0 1.968.884 1.968 1.967v1.968H9.486zm0 .992c1.083 0 1.968.884 1.968 1.967a1.973 1.973 0 0 1-1.968 1.968H4.56a1.973 1.973 0 0 1-1.967-1.968c0-1.083.884-1.967 1.967-1.967h4.927zm7.987 1.967c0-1.083.885-1.967 1.968-1.967s1.967.884 1.967 1.967a1.973 1.973 0 0 1-1.967 1.968h-1.968V9.486zm-.992 0a1.973 1.973 0 0 1-1.967 1.968 1.973 1.973 0 0 1-1.968-1.968V4.56c0-1.083.885-1.967 1.968-1.967s1.967.884 1.967 1.967v4.927zm-1.967 7.987c1.083 0 1.967.885 1.967 1.968a1.973 1.973 0 0 1-1.967 1.967 1.973 1.973 0 0 1-1.968-1.967v-1.968h1.968zm0-.992a1.973 1.973 0 0 1-1.968-1.967c0-1.083.885-1.968 1.968-1.968h4.927c1.083 0 1.967.885 1.967 1.968a1.973 1.973 0 0 1-1.967 1.967h-4.927z" />
                  </svg>
                  <AlternateEmailRoundedIcon />
                </Box>
                <Grid item xs={3.3} sx={{ textAlign: "center" }}>
                  <img alt="Property" src={closingData.property_img}></img>
                </Grid>
                <Grid className={classes.headerCommissionDetails} item container xs={8.7}>
                  <Grid item xs={7}>
                    <Box>
                      <Typography variant="h3">{closingData.street}</Typography>
                      <Typography>{`${closingData.city}, ${closingData.state} ${closingData.zipcode}`}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={5}>
                    <Box>
                      <Box sx={{ marginTop: "10px" }}>
                        <Typography>Listed By</Typography>
                        <Typography variant="h4">{closingData.agent_name}</Typography>
                      </Box>
                      <Box>
                        <Typography>Submitted By</Typography>
                        <Typography variant="h5">{closingData.closed_by}</Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid className={classes.headerCommissionFooter} item xs={11} lg={10} xl={9}>
                    <Box>
                      <Typography variant="h6">{formatter.format(closingData.price)}</Typography>
                      <Typography>Closed price</Typography>
                    </Box>
                    <Box>
                      <Typography variant="h6">{formatter.format(closingData.gci)}</Typography>
                      <Typography>GCI</Typography>
                    </Box>
                    <Box>
                      <Typography variant="h6">{closingData.closing_date}</Typography>
                      <Typography>Closing Date</Typography>
                    </Box>
                    <Box>
                      <Typography variant="h6">{formatter.format(closingData.company_dollar)}</Typography>
                      <Typography>Company Dollar</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          )}

          {/* bottom page */}

          <Grid className={classes.mainCommission} container>
            {openPreview ? (
              <Grid className={classes.mainCommissionLeft} item xs={12}>
                <Box className={classes.mainCommissionSection} sx={{ marginBottom: "58px" }}>
                  {renderTitle(closingData.gci, "Gross Commission Received")}
                  {renderContent(grossCommisions, grossCommisionsDeductions)}
                </Box>
                <Box className={classes.mainCommissionSection} sx={{ marginBottom: "78px" }}>
                  {renderTitle(grossCommisionsAmounts.net, {
                    insert: (
                      <Fragment>
                        Remaining with{" "}
                        <strong>
                          {assignedAgentSplitValue}
                          {assignedAgentSplitType} (${agentSplitAmounts.gross})
                        </strong>{" "}
                        split to the agent
                      </Fragment>
                    ),
                  })}
                  {renderContent({
                    amountDeducted: agentSplitAmounts.net,
                    name: closingData?.agent_data?.agent_name,
                    via: closingData?.agent_data?.payment_preference,
                  })}

                  {renderContent(agentSplit, agentSplitDeductions)}
                  {/* <Box className={classes.commissionDeductMessage}>
                <AttachMoneyRoundedIcon />
                <Typography>Outstanding Agent Tab: {"**plchlder**"}</Typography>
                <Box>
                  <Button variant="outlined">Deduct</Button>
                  <Button variant="text">Partial</Button>
                </Box>
              </Box> */}
                </Box>
                <Box className={classes.mainCommissionSection} sx={{ marginBottom: "70px" }}>
                  {renderTitle(companyDollarsAmounts.gross, "Total Company Dollar")}
                  {renderContent(companyDollars, companyDollarsDeductions)}
                </Box>
                <Box className={classes.mainCommission}>{renderTitle(companyDollarsAmounts.net, "Net Company Dollar", true)}</Box>
                {location.pathname.startsWith("/finance/closings/processed/") ? null : (
                  <Box sx={{ textAlign: "end", marginTop: "80px" }}>
                    {actionLoading ? (
                      <Box sx={{ margin: "auto" }}>
                        <CircularProgress size={"40px"} />
                      </Box>
                    ) : (
                      <Box className={classes.customButtonGroup}>
                        <Button
                          variant="text"
                          onClick={(e) => {
                            setOpenPreview(false);
                          }}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="contained"
                          onClick={handleApprove}
                          sx={{
                            fontSize: "13px",
                            backgroundColor: "black",
                            "&:focus": {
                              backgroundColor: "black",
                            },
                          }}
                        >
                          Approve
                        </Button>
                      </Box>
                    )}
                  </Box>
                )}
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Card className={classes.closingReportArea} variant="outlined">
                  <Box className={classes.closingReportAreaHeading}>
                    {isContract ? null : <Typography>Closing Report</Typography>}

                    <Autocomplete
                      disableClearable
                      options={data}
                      getOptionLabel={(option) => option.name}
                      onChange={(e, option) => {
                        setSelectedTemplate(option);
                        setAssignedAgentSplitValue(option.agentPer);
                        setAssignedAgentSplitType("%");
                        let { gci, agent_split, company_dollar } = option.conditions.reduce(
                          (prev, curr) => {
                            prev[curr.type].push({
                              value: curr.value,
                              paymentType: curr.paymentType,
                              acctCode: curr.acctCode,
                              percentageOf: curr.percentageOf,
                              payTo: curr.paidTo,
                              via: vendorsObj[curr.paidTo].payment_preference,
                              name: vendorsObj[curr.paidTo].name,
                              user_id: vendorsObj[curr.paidTo].user_id,
                              tax_id: vendorsObj[curr.paidTo].tax_id,
                            });
                            return prev;
                          },
                          {
                            gci: [],
                            agent_split: [],
                            company_dollar: [],
                          }
                        );
                        setGrossCommisions(gci);
                        setCompanyDollars(company_dollar);
                        setAgentSplit(agent_split);
                      }}
                      sx={{ minWidth: "280px" }}
                      value={selectedTemplate}
                      isOptionEqualToValue={(option, value) => option?.id === value?.id}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Quick Template"
                          aria-describedby="stepper-linear-event_types"
                          size="small"
                          variant="outlined"
                          fullWidth
                          required
                          value={selectedTemplate}
                        />
                      )}
                    ></Autocomplete>
                    {isContract ? (
                      <TextField
                        value={grossCommisionsAmounts.gross}
                        sx={{ ml: "15px" }}
                        placeholder="Enter GCI value"
                        size="small"
                        onChange={(e) => {
                          setGrossCommisionsAmounts({
                            gross: e.target.value,
                            net: e.target.value,
                          });
                        }}
                        onBlur={(e) => {
                          setReloadCalculation(!reloadCalculation);
                        }}
                      />
                    ) : null}
                  </Box>

                  {/* start gross commission area */}
                  <Box className={classes.grossCommissionArea}>
                    <Grid container spacing={2}>
                      <Grid item sm={12} md={9}>
                        <Stack direction="row" spacing={2} className={classes.grossCommissionTopLeft} alignItems={"center"}>
                          <Typography className={classes.grossCommissionTopLeftHeading}>
                            {grossCommisionsAmounts.gross?.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                          </Typography>
                          <Typography className={classes.grossCommissionTopLeftContent}>Gross Commission Recieved on 12/20/2021 via wire</Typography>
                        </Stack>
                      </Grid>
                      <Grid item sm={12} md={3}>
                        <Typography className={classes.grossCommissionTopLeftHeading}>
                          {grossCommisionsAmounts.gross?.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box className={classes.grossCommissionInputsArea}>
                    {grossCommisions.map((item, index) => (
                      <Grid container spacing={2} key={index}>
                        <Grid item sm={12} md={9}>
                          <Stack direction="row" spacing={1} alignItems={"center"} sx={{ mb: "10px" }}>
                            <TextField
                              placeholder="value"
                              size="small"
                              sx={{ width: "80px" }}
                              defaultValue={item.value}
                              type="number"
                              onBlur={(e) => {
                                let gcTemp = [...grossCommisions];
                                gcTemp[index].value = e.target.value;
                                setGrossCommisions([...gcTemp]);
                              }}
                            />
                            <TextField
                              defaultValue={"%"}
                              select
                              size="small"
                              value={item.paymentType}
                              onChange={(e) => {
                                let gcTemp = [...grossCommisions];
                                gcTemp[index].paymentType = e.target.value;
                                setGrossCommisions([...gcTemp]);
                              }}
                            >
                              <MenuItem value="$">$</MenuItem>
                              <MenuItem value="%">%</MenuItem>
                            </TextField>
                            {item.paymentType === "%" && (
                              <>
                                <Typography>of</Typography>
                                <TextField
                                  select
                                  size="small"
                                  sx={{ width: "150px" }}
                                  value={item.percentageOf}
                                  onChange={(e) => {
                                    let gcTemp = [...grossCommisions];
                                    gcTemp[index].percentageOf = e.target.value;
                                    setGrossCommisions([...gcTemp]);
                                  }}
                                >
                                  <MenuItem value="gci">GCI</MenuItem>
                                  <MenuItem value="contractPrice">Contact Price</MenuItem>
                                </TextField>
                              </>
                            )}

                            <Typography>to</Typography>
                            <TextField
                              select
                              size="small"
                              sx={{ width: "150px" }}
                              value={item.payTo}
                              onChange={(e) => {
                                let gciTemp = [...grossCommisions];
                                gciTemp[index].payTo = e.target.value;
                                gciTemp[index].name = e.target.value === 0 ? "Tab Repayment" : vendorsObj[e.target.value].name;
                                gciTemp[index].user_id = e.target.value === 0 ? closingData.agent_id : vendorsObj[e.target.value].user_id;
                                gciTemp[index].via = e.target.value === 0 ? "tab_repayment" : vendorsObj[e.target.value].payment_preference;
                                if (e.target.value === 0) {
                                  gciTemp[index].acctCode = "tabRepayment";
                                }
                                setGrossCommisions([...gciTemp]);
                              }}
                            >
                              {vendorList.map((vendor, i) => {
                                return (
                                  <MenuItem key={i} value={vendor.id}>
                                    {vendor.name + " - " + vendor.id}
                                  </MenuItem>
                                );
                              })}
                              <MenuItem value={0}>Tab Repayment</MenuItem>
                            </TextField>
                            <Typography>Code</Typography>
                            <TextField
                              select
                              size="small"
                              sx={{ width: "150px" }}
                              value={item.acctCode}
                              onChange={(e) => {
                                let gciTemp = [...grossCommisions];
                                gciTemp[index].acctCode = e.target.value;
                                setGrossCommisions([...gciTemp]);
                              }}
                            >
                              {[
                                { value: "referralFee", label: "Referral Fee" },
                                { value: "mlsFee", label: "Mls Fee" },
                                {
                                  value: "teamLeaderComm",
                                  label: "Team Leader Comm",
                                },
                                { value: "other", label: "Other" },
                                { value: "tabRepayment", label: "Tab Repayment" },
                              ].map((item, index) => (
                                <MenuItem key={index} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </TextField>
                            <Button variant="outlined" className={classes.viaCheckButton}>
                              Via {item.via ? item.via : "Check"}
                            </Button>
                            <IconButton onClick={() => handleDeleteGrossDeduction(index)} size="small">
                              <DeleteForeverIcon />
                            </IconButton>
                          </Stack>
                          <Stack></Stack>
                        </Grid>
                        <Grid item sm={12} md={3}>
                          <Typography className={classes.grossCommissionTopLeftHeading}>
                            -(
                            {grossCommisionsDeductions[index]
                              ? grossCommisionsDeductions[index].toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                })
                              : ""}
                            )
                          </Typography>
                        </Grid>
                      </Grid>
                    ))}

                    <Grid container spacing={2}>
                      <Grid item sm={12} md={9}>
                        <Button onClick={() => handleGrossDeduction()} variant="contained" size="small" className={classes.addDeductionButton}>
                          Add Deduction
                        </Button>
                      </Grid>
                      <Grid item sm={12} md={3}></Grid>
                    </Grid>
                  </Box>
                  {/* end gross commission area */}
                  {/* start remaining with area */}
                  <Box className={classes.grossCommissionArea}>
                    <Grid container spacing={2}>
                      <Grid item sm={12} md={9}>
                        <Stack direction="row" spacing={2} className={classes.grossCommissionTopLeft} alignItems={"center"}>
                          <Typography className={classes.grossCommissionTopLeftHeading}>
                            {grossCommisionsAmounts.net?.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                          </Typography>
                          <Typography className={classes.grossCommissionTopLeftContent} sx={{ display: "flex", alignItems: "center" }}>
                            Remaining, with
                            <TextField
                              placeholder="value"
                              size="small"
                              sx={{
                                width: "80px",
                                ml: "15px",
                                mr: "10px",
                                mb: "4px",
                              }}
                              value={assignedAgentSplitValue}
                              type="number"
                              inputProps={{
                                maxLength: 3,
                                step: "1",
                              }}
                              onChange={(e) => {
                                setAssignedAgentSplitValue(e.target.value);
                              }}
                            />
                            <TextField
                              defaultValue={"%"}
                              sx={{ mr: "15px", mb: "4px" }}
                              select
                              size="small"
                              value={assignedAgentSplitType}
                              onChange={(e) => {
                                setAssignedAgentSplitType(e.target.value);
                              }}
                            >
                              <MenuItem value="$">$</MenuItem>
                              <MenuItem value="%">%</MenuItem>
                            </TextField>{" "}
                            <b>
                              {" "}
                              (
                              {agentSplitAmounts.gross?.toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })}
                              ){" "}
                            </b>{" "}
                            split to the agent:
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item sm={12} md={3}>
                        <Typography className={classes.grossCommissionBottomLeftHeadingBorder}>
                          {agentSplitAmounts.gross?.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box className={classes.grossCommissionInputsArea}>
                    {agentSplit?.map((item, index) => (
                      <Grid container spacing={2} key={index}>
                        <Grid item sm={12} md={9}>
                          <Stack direction="row" spacing={1} alignItems={"center"} sx={{ mb: "10px" }}>
                            <TextField
                              placeholder="value"
                              size="small"
                              sx={{ width: "80px" }}
                              defaultValue={item.value}
                              type="number"
                              onBlur={(e) => {
                                let agentTemp = [...agentSplit];
                                agentTemp[index].value = e.target.value;
                                setAgentSplit([...agentTemp]);
                              }}
                            />
                            <TextField
                              defaultValue={"%"}
                              select
                              size="small"
                              value={item.paymentType}
                              onChange={(e) => {
                                let agentTemp = [...agentSplit];
                                agentTemp[index].paymentType = e.target.value;
                                setAgentSplit([...agentTemp]);
                              }}
                            >
                              <MenuItem value="$">$</MenuItem>
                              <MenuItem value="%">%</MenuItem>
                            </TextField>
                            {item.paymentType === "%" && (
                              <>
                                <Typography>of</Typography>
                                <TextField
                                  select
                                  size="small"
                                  sx={{ width: "150px" }}
                                  value={item.percentageOf}
                                  onChange={(e) => {
                                    let agentTemp = [...agentSplit];
                                    agentTemp[index].percentageOf = e.target.value;
                                    setAgentSplit([...agentTemp]);
                                  }}
                                >
                                  <MenuItem value="gci">GCI</MenuItem>
                                  <MenuItem value="contractPrice">Contact Price</MenuItem>
                                </TextField>
                              </>
                            )}

                            <Typography>to</Typography>
                            <TextField
                              select
                              size="small"
                              sx={{ width: "150px" }}
                              value={item.payTo}
                              onChange={(e) => {
                                let agentTemp = [...agentSplit];
                                agentTemp[index].payTo = e.target.value;
                                agentTemp[index].name = e.target.value === 0 ? "Tab Repayment" : vendorsObj[e.target.value].name;
                                agentTemp[index].user_id = e.target.value === 0 ? closingData.agent_id : vendorsObj[e.target.value].user_id;
                                agentTemp[index].via = e.target.value === 0 ? "tab_repayment" : vendorsObj[e.target.value].payment_preference;

                                if (e.target.value === 0) {
                                  agentTemp[index].acctCode = "tabRepayment";
                                }
                                setAgentSplit([...agentTemp]);
                              }}
                            >
                              {vendorList.map((vendor, i) => {
                                return (
                                  <MenuItem key={i} value={vendor.id}>
                                    {vendor.name + " - " + vendor.id}
                                  </MenuItem>
                                );
                              })}
                              <MenuItem value={0}>Tab Repayment</MenuItem>
                            </TextField>
                            <Typography>Code</Typography>
                            <TextField
                              select
                              size="small"
                              sx={{ width: "150px" }}
                              value={item.acctCode}
                              onChange={(e) => {
                                let agentTemp = [...agentSplit];
                                agentTemp[index].acctCode = e.target.value;
                                setAgentSplit([...agentTemp]);
                              }}
                            >
                              {[
                                { value: "referralFee", label: "Referral Fee" },
                                { value: "mlsFee", label: "Mls Fee" },
                                {
                                  value: "teamLeaderComm",
                                  label: "Team Leader Comm",
                                },
                                { value: "other", label: "Other" },
                                { value: "tabRepayment", label: "Tab Repayment" },
                              ].map((item, index) => (
                                <MenuItem key={index} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </TextField>
                            <Button variant="outlined" className={classes.viaCheckButton}>
                              Via {item.via ? item.via : "Check"}
                            </Button>
                            <IconButton onClick={() => handleDeleteAgentSplit(index)} size="small">
                              <DeleteForeverIcon />
                            </IconButton>
                          </Stack>
                          <Stack></Stack>
                        </Grid>
                        <Grid item sm={12} md={3}>
                          <Typography className={classes.grossCommissionTopLeftHeading}>
                            -(
                            {agentSplitDeductions[index]
                              ? agentSplitDeductions[index].toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                })
                              : ""}
                            )
                          </Typography>
                        </Grid>
                      </Grid>
                    ))}

                    <Grid container spacing={2}>
                      <Grid item sm={12} md={9}>
                        <Stack direction={"row"} spacing={2}>
                          <Button onClick={() => handleAddAgentSplit()} variant="contained" size="small" className={classes.addDeductionButton}>
                            Add Deduction
                          </Button>
                          <Button
                            onClick={() => {
                              // let commisions = [...remainingWith]
                              // commisions.push({ id: 2 });
                              // setRemainingWith(commisions);
                            }}
                            variant="contained"
                            size="small"
                            className={classes.addDeductionButton}
                            sx={{ backgroundColor: "#B70E0E !important" }}
                          >
                            Agent Outstanding Tab: ${closingData?.agent_data?.balance}
                          </Button>
                        </Stack>
                      </Grid>
                      <Grid item sm={12} md={3}>
                        <Typography className={classes.grossCommissionTopLeftHeadingBorder}>
                          {agentSplitAmounts.net?.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  {/* end  remaining with area */}
                  {/* start total company dollar */}
                  <Box className={classes.grossCommissionArea}>
                    <Grid container spacing={2}>
                      <Grid item sm={12} md={9}>
                        <Stack direction="row" spacing={2} className={classes.grossCommissionTopLeft} alignItems={"center"}>
                          <Typography className={classes.grossCommissionTopLeftHeading}>
                            {companyDollarsAmounts.gross?.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                          </Typography>
                          <Typography className={classes.grossCommissionTopLeftContent}>Total Company Dollar</Typography>
                        </Stack>
                      </Grid>
                      <Grid item sm={12} md={3}>
                        <Typography className={classes.grossCommissionTopLeftHeading}></Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box className={classes.grossCommissionInputsArea}>
                    {companyDollars.map((item, index) => (
                      <Grid container spacing={2} key={index}>
                        <Grid item sm={12} md={9}>
                          <Stack direction="row" spacing={1} alignItems={"center"} sx={{ mb: "10px" }}>
                            <TextField
                              placeholder="value"
                              size="small"
                              sx={{ width: "80px" }}
                              defaultValue={item.value}
                              type="number"
                              onBlur={(e) => {
                                let companyTemp = [...companyDollars];
                                companyTemp[index].value = e.target.value;
                                setCompanyDollars([...companyTemp]);
                              }}
                            />
                            <TextField
                              defaultValue={"%"}
                              select
                              size="small"
                              value={item.paymentType}
                              onChange={(e) => {
                                let companyTemp = [...companyDollars];
                                companyTemp[index].paymentType = e.target.value;
                                setCompanyDollars([...companyTemp]);
                              }}
                            >
                              <MenuItem value="$">$</MenuItem>
                              <MenuItem value="%">%</MenuItem>
                            </TextField>
                            {item.paymentType === "%" && (
                              <>
                                <Typography>of</Typography>
                                <TextField
                                  select
                                  size="small"
                                  sx={{ width: "150px" }}
                                  value={item.percentageOf}
                                  onChange={(e) => {
                                    let companyTemp = [...companyDollars];
                                    companyTemp[index].percentageOf = e.target.value;
                                    setCompanyDollars([...companyTemp]);
                                  }}
                                >
                                  <MenuItem value="gci">GCI</MenuItem>
                                  <MenuItem value="contractPrice">Contact Price</MenuItem>
                                </TextField>
                              </>
                            )}

                            <Typography>to</Typography>
                            <TextField
                              select
                              size="small"
                              sx={{ width: "150px" }}
                              value={item.payTo}
                              onChange={(e) => {
                                let companyTemp = [...companyDollars];
                                companyTemp[index].payTo = e.target.value;
                                companyTemp[index].name = e.target.value === 0 ? "Tab Repayment" : vendorsObj[e.target.value].name;
                                companyTemp[index].user_id = e.target.value === 0 ? closingData.agent_id : vendorsObj[e.target.value].user_id;
                                companyTemp[index].via = e.target.value === 0 ? "tab_repayment" : vendorsObj[e.target.value].payment_preference;
                                if (e.target.value === 0) {
                                  agentTemp[index].acctCode = "tabRepayment";
                                }
                                setCompanyDollars([...companyTemp]);
                              }}
                            >
                              {vendorList.map((vendor, i) => {
                                return (
                                  <MenuItem key={i} value={vendor.id}>
                                    {vendor.name + " - " + vendor.id}
                                  </MenuItem>
                                );
                              })}
                              <MenuItem value={0}>Tab Repayment</MenuItem>
                            </TextField>
                            <Typography>Code</Typography>
                            <TextField
                              select
                              size="small"
                              sx={{ width: "150px" }}
                              value={item.acctCode}
                              onChange={(e) => {
                                let companyTemp = [...companyDollars];
                                companyTemp[index].acctCode = e.target.value;
                                setCompanyDollars([...companyTemp]);
                              }}
                            >
                              {[
                                { value: "referralFee", label: "Referral Fee" },
                                { value: "mlsFee", label: "Mls Fee" },
                                {
                                  value: "teamLeaderComm",
                                  label: "Team Leader Comm",
                                },
                                { value: "other", label: "Other" },
                                { value: "tabRepayment", label: "Tab Repayment" },
                              ].map((item, index) => (
                                <MenuItem key={index} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </TextField>
                            <Button variant="outlined" className={classes.viaCheckButton}>
                              Via {item.via ? item.via : "Check"}
                            </Button>
                            <IconButton onClick={() => handleDeleteCompanyDollarsRow(index)} size="small">
                              <DeleteForeverIcon />
                            </IconButton>
                          </Stack>
                          <Stack></Stack>
                        </Grid>
                        <Grid item sm={12} md={3}>
                          <Typography className={classes.grossCommissionTopLeftHeading}>
                            -(
                            {companyDollarsDeductions[index]
                              ? companyDollarsDeductions[index].toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                })
                              : ""}
                            )
                          </Typography>
                        </Grid>
                      </Grid>
                    ))}

                    <Grid container spacing={2}>
                      <Grid item sm={12} md={9}>
                        <Button onClick={() => handleAddCompanyDollars()} variant="contained" size="small" className={classes.addDeductionButton}>
                          Add Deduction
                        </Button>
                      </Grid>
                      <Grid item sm={12} md={3}>
                        <Typography className={classes.grossCommissionTopLeftHeadingBorder}>
                          {companyDollarsAmounts.net?.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  {/* end total company dollar */}
                  <Grid container spacing={2} sx={{ mt: "30px", mb: "20px" }}>
                    <Grid item sm={12} md={5}>
                      <Stack direction="row" spacing={2} className={classes.grossCommissionTopLeft} alignItems={"center"}>
                        <Typography className={classes.grossCommissionTopLeftHeading}>
                          {companyDollarsAmounts.net?.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </Typography>
                        <Typography className={classes.grossCommissionTopLeftContent}>Net Company Dollar</Typography>
                      </Stack>
                    </Grid>
                    <Grid item sm={12} md={7}>
                      {location.pathname.startsWith("/finance/closings/processed/") ? null : (
                        <Stack direction={"row"} spacing={4}>
                          {isContract ? null : (
                            <Button
                              onClick={() => {
                                setEditContract(true);
                              }}
                              variant="outlined"
                              className={classes.viaCheckButton}
                              sx={{ fontSize: "22px", minWidth: "120px" }}
                            >
                              Edit Closing
                            </Button>
                          )}
                          {isContract ? null : (
                            <Button
                              onClick={() => {
                                setOpenPreview(true);
                                saveRule();
                              }}
                              variant="outlined"
                              className={classes.viaCheckButton}
                              sx={{ fontSize: "22px", minWidth: "120px" }}
                            >
                              Preview
                            </Button>
                          )}
                          <Button
                            onClick={() => {
                              saveRuleWithoutNotification();
                            }}
                            variant="outlined"
                            className={classes.viaCheckButton}
                            sx={{ fontSize: "22px", minWidth: "120px" }}
                          >
                            Save
                          </Button>
                          {isContract ? null : (
                            <Button
                              variant="contained"
                              className={classes.addDeductionButton}
                              sx={{ fontSize: "22px", minWidth: "120px" }}
                              onClick={handleApprove}
                            >
                              Approve
                            </Button>
                          )}
                        </Stack>
                      )}
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            )}

            {isContract ? null : (
              <Grid item xs={12} sx={{ mt: "30px", mb: "30px" }}>
                <Card className={classes.mainCommissionRight} variant="outlined">
                  <Typography className={classes.closingDocumentHeading}>Closing Documents</Typography>
                  <Grid container spacing={2}>
                    {closingData?.transaction_documents?.map((document) => {
                      if (document.file_src) {
                        return (
                          <Grid item sm={12} md={4} lg={3}>
                            <center>
                              <Button
                                onClick={(e) => {
                                  openPdf(document.file_src);
                                }}
                                sx={{
                                  fontSize: "12px",
                                  backgroundColor: "black",
                                  mt: "40px",
                                  textTransform: "capitalize",
                                  color: "white",
                                  "&:hover": {
                                    backgroundColor: "black",
                                  },
                                }}
                              >
                                View Document
                              </Button>
                            </center>
                            <Box
                              className={classes.commissionDocument}
                              onClick={(e) => {
                                openPdf(document.file_src);
                              }}
                            >
                              <Box
                                component="img"
                                sx={{
                                  height: 250,
                                  border: "1px solid black",
                                  maxHeight: { xs: 233, md: 167 },
                                  maxWidth: { xs: 350, md: 250 },
                                }}
                                alt={document.document.name}
                                src={`${process.env.REACT_APP_DOCUMENT_IMG}${encodeURIComponent(document.file_src.replace(".pdf", ".png"))}`}
                              />

                              <Typography>{document.document.name}</Typography>
                            </Box>
                          </Grid>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </Grid>
                </Card>
              </Grid>
            )}
          </Grid>
          <ConfirmModal
            open={confirmApprove}
            setOpen={setConfirmApprove}
            handleConfirm={handleConfirm}
            title={"Are you sure! you want to approve this Payment?"}
            hidetrash={true}
            loading={actionLoading}
          />
          {editContract && (
            <EditContract
              open={editContract}
              defaultValues={{
                gci: closingData.gci,
                closing_date: moment(closingData.closing_date).format("YYYY-MM-DD"),
                price: closingData.price,
                commission_plain_id: closingData.commission_plain_id,
                closing_notes: closingData.closing_notes,
              }}
              id={id}
              setOpen={setEditContract}
            />
          )}
        </Box>
      )}
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() => setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })}
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </Box>
  );
}

export default withStyles(styleSheet, { name: "FinanceFile" })(FinanceFile);
