// import React, { useState, useContext } from "react";
// import Box from "@mui/material/Box";
// import { DataGridPro } from "@mui/x-data-grid-pro";
// import { withStyles } from "@mui/styles";
// import { styleSheet } from "./style";
// import { useNavigate } from "react-router-dom";
// import { Stack, Skeleton, ListItemText } from "@mui/material";
// import { NameCard, StageCell } from "./TableCellsLead";
// import moment from "moment";
// import TableSkeleton from "../../../../components/loader/tableLoader";
// import { getLeadsListAPI } from "../../../../redux/leads/getLeadsListAPI";
// import Avatar from "../../../../components/uiElements/avatar";
// import PhoneIphoneRoundedIcon from "@mui/icons-material/PhoneIphoneRounded";
// import TextsmsRoundedIcon from "@mui/icons-material/TextsmsRounded";
// import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
// import { useDispatch, useSelector } from "react-redux";
// import { handleUpdateModalState } from "../../../../redux/widgets";
// import ResponseAlert from "../../../../components/responseAlert";
// import { handleMaximizeTab } from "../../../../redux/widgets";
// import { AppContext } from "../../../../components/callPanel/callContext";
// // Table Columns
// const LeadsTable = ({
//   classes,
//   data,
//   loading,
//   filter,
//   setFilter,
//   metadata,
// }) => {
//   const [errorAlert, setErrorAlert] = useState({
//     errorMsg: "",
//     errorType: "",
//     isOpen: false,
//   });

//   const dispatch = useDispatch();
//   const { dialNumber } = useContext(AppContext);
//   let navigate = useNavigate();
//   const { list: usersList } = useSelector(
//     (item) => item.users.storedListOfUsers
//   );
//   const rightPositions = useSelector((state) => state.widgets.rightPositions);
//   const widgets = useSelector((state) => state.widgets);

//   // Detail view trigger for Leads
//   const onRowClick = ({ id }) => {
//     navigate(`/leads/people/details/${id}/`);
//   };

//   const handleOpenWidget = (type, phone, email) => {
//     if (!widgets[type]) {
//       if (rightPositions.length) {
//         if (
//           rightPositions[rightPositions.length - 1].right + 1450 >
//           window.innerWidth
//         ) {
//           handleAlert();
//         } else {
//           if (type === "sendTextOpen") {
//             dispatch(
//               handleMaximizeTab({
//                 type: type,
//                 action: true,
//                 tabDetail: {
//                   toNumber: [phone],
//                 },
//               })
//             );
//           } else {
//             dispatch(
//               handleMaximizeTab({
//                 type: type,
//                 action: true,
//                 tabDetail: {
//                   toEmailList: [{ email: email, name: "" }],
//                 },
//               })
//             );
//           }
//         }
//       } else {
//         if (type === "sendTextOpen") {
//           dispatch(
//             handleMaximizeTab({
//               type: type,
//               action: true,
//               tabDetail: {
//                 toNumber: [phone],
//               },
//             })
//           );
//         } else {
//           dispatch(
//             handleMaximizeTab({
//               type: type,
//               action: true,
//               tabDetail: {
//                 toEmailList: [{ email: email, name: "" }],
//               },
//             })
//           );
//         }
//       }
//     } else {
//       setErrorAlert({
//         errorMsg: "You can't open this widget because already opened",
//         errorType: "warning",
//         isOpen: true,
//       });
//     }
//   };
//   const handleAlert = () => {
//     setErrorAlert({
//       errorMsg:
//         "You can't open this widget because browser width is not enough for this!",
//       errorType: "warning",
//       isOpen: true,
//     });
//   };

//   const columns = [
//     {
//       field: "name",
//       headerName: "Name",
//       renderCell: ({ row }) => {
//         return (
//           <Box onClick={() => onRowClick(row)}>
//             <NameCard
//               name={row?.name ? row.name : "Null"}
//               lastActivity={row.lastActivity}
//               avatar={""}
//               market={row.source}
//             />
//           </Box>
//         );
//       },
//       width: 250,
//       editable: false,
//       sortable: true,
//     },
//     {
//       field: "phone",
//       headerName: "Phones",
//       renderCell: ({ row }) => {
//         return row.phones?.length && row.phones[0]?.value ? (
//           <Stack alignItems={"center"} direction="row" spacing={1}>
//             {" "}
//             <Avatar
//               onClick={() => {
//                 if (row.phones[0]?.status === "Valid") {
//                   dialNumber(
//                     row.phones[0]?.value,
//                     null,
//                     row?.name,
//                     null,
//                     row.id
//                   );
//                 }
//               }}
//               sx={{
//                 bgcolor: "rgba(81, 172, 10, 0.96)",
//                 width: "28px",
//                 height: "28px",
//                 cursor: "pointer",
//               }}
//               fontSize="small"
//             >
//               <PhoneIphoneRoundedIcon fontSize="small" />
//             </Avatar>{" "}
//             <Avatar
//               sx={{
//                 bgcolor: "rgba(174, 52, 193, 0.79)",
//                 width: "28px",
//                 height: "28px",
//                 cursor: "pointer",
//               }}
//               onClick={() =>
//                 handleOpenWidget("sendTextOpen", row.phones[0]?.value)
//               }
//             >
//               <TextsmsRoundedIcon sx={{ fontSize: "18px" }} fontSize="small" />
//             </Avatar>{" "}
//             <Box onClick={() => onRowClick(row)} sx={{ fontWeight: "500" }}>
//               {row.phones[0]?.value}
//             </Box>
//           </Stack>
//         ) : (
//           "--"
//         );
//       },
//       width: 220,
//       editable: false,
//       sortable: true,
//     },
//     {
//       field: "emails",
//       headerName: "Email",
//       renderCell: ({ row }) => {
//         return row?.emails?.length &&
//           row?.emails.find((item) => item.isPrimary)?.value ? (
//           <Stack alignItems={"center"} direction="row" spacing={1}>
//             {" "}
//             <Avatar
//               sx={{
//                 bgcolor: "#7AE3FA",
//                 width: "28px",
//                 height: "28px",
//                 cursor: "pointer",
//               }}
//               fontSize="small"
//               onClick={() =>
//                 handleOpenWidget(
//                   "sendEmailOpen",
//                   null,
//                   row?.emails.find((item) => item.isPrimary)?.value
//                 )
//               }
//             >
//               <EmailRoundedIcon fontSize="small" />
//             </Avatar>{" "}
//             <Box onClick={() => onRowClick(row)} sx={{ fontWeight: "500" }}>
//               {row?.emails.find((item) => item.isPrimary)?.value}
//             </Box>
//           </Stack>
//         ) : (
//           "--"
//         );
//       },
//       minWidth: 260,
//       editable: false,
//       type: "dateTime",
//       sortable: true,
//       flex: 1,
//     },
//     {
//       field: "stage",
//       headerName: "Stage",
//       renderCell: ({ row }) => {
//         return row?.stage ? (
//           <Box onClick={() => onRowClick(row)}>
//             <StageCell name={row.stage} />
//           </Box>
//         ) : (
//           "--"
//         );
//       },
//       editable: false,
//       sortable: false,
//       flex: 0.5,
//       minWidth: 120,
//     },
//     {
//       field: "Assigned",
//       headerName: "Assigned to",
//       sortComparator: (v1, v2) => v1?.first_name.localeCompare(v2?.first_name),
//       renderCell: (params) => {
//         return params?.row?.assignedUserId ? (
//           <Stack
//             onClick={() => onRowClick(params.row)}
//             direction="row"
//             justifyContent="center"
//             alignItems="center"
//             spacing={2}
//           >
//             <Avatar
//               sx={{ fontSize: "12px", width: "30px", height: "30px" }}
//               src={
//                 usersList.find((k) => k.id === params?.row?.assignedUserId)
//                   ?.profile_images?.profile_img
//               }
//               variant="circled"
//             >
//               {`${
//                 params?.row?.assignedTo?.slice(0, 1) +
//                 params?.row?.assignedTo?.split(" ")[1]?.slice(0, 1)
//               }`}
//             </Avatar>
//             <ListItemText
//               sx={{ "& 	.MuiListItemText-primary": { fontSize: "14px" } }}
//               primary={
//                 params?.row?.assignedTo
//                   ? params?.row?.assignedTo
//                   : usersList.find((k) => k.id === params?.row?.assignedUserId)
//                       ?.full_name
//               }
//             />
//           </Stack>
//         ) : null;
//       },
//       width: 170,
//       editable: false,
//       sortable: true,
//     },
//     {
//       field: "Activity",
//       headerName: "Last Activity",
//       renderCell: (row) => {
//         return (
//           <Box onClick={() => onRowClick(row)}>
//             {row.activity ? "Active Zips found!" : ""}
//           </Box>
//         );
//       },
//       editable: false,
//       sortable: false,
//       flex: 1,
//     },
//     {
//       field: "created",
//       headerName: "Created",
//       renderCell: ({ row }) => {
//         return (
//           <Box onClick={() => onRowClick(row)}>
//             {row.created ? moment(row.created).format("ll") : "--"}
//           </Box>
//         );
//       },
//       editable: false,
//       sortable: false,
//       flex: 1,
//     },
//   ];

//   return (
//     <Box className={classes.tableRoot}>
//       <DataGridPro
//         loader
//         columns={columns}
//         rows={data}
//         loading={loading}
//         components={{
//           LoadingOverlay: TableSkeleton,
//         }}
//         onRowsScrollEnd={(e) => {
//           if (metadata?.total > e.virtualRowsCount && !loading) {
//             dispatch(
//               getLeadsListAPI({
//                 offset: Number(metadata.offset) + Number(metadata.limit),
//                 limit: metadata.limit,
//               })
//             );
//           }
//         }}
//         rowHeight={56}
//         checkboxSelection
//         disableSelectionOnClick
//       />
//       <ResponseAlert
//         open={errorAlert.isOpen}
//         setOpen={() =>
//           setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
//         }
//         alertType={errorAlert.errorType}
//         alertMessage={errorAlert.errorMsg}
//       />
//     </Box>
//   );
// };

// export default withStyles(styleSheet, { name: "leadsTableStyle" })(LeadsTable);

import React, { useContext, useState } from "react";
import Box from "@mui/material/Box";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style";
import { useNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import { LeadsCustomFilters } from "../filters/LeadsCustomFilters";
import { CustomFilterableDataGrid } from "../../../../components/uiElements/customFilterabeDataGrid/CustomFilterableDataGrid";
import { LeadsCustomToolbar } from "../filters/LeadsCustomToolbar";
import { SidebarAllFilter } from "../filters/SidebarAllFilter";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { getColumns } from "./constants";
import { useEffect } from "react";
import {
  getLeadsIdsAfterFilter,
  setPeopleColumnsOrder,
  setPeopleColumnsVisibility,
  setPeopleFilter,
  setPeopleSort,
} from "../../../../redux/leads/getLeadList";
import { getLeadsListAPI } from "../../../../redux/leads/getLeadsListAPI";
import { AppContext } from "../../../../components/callPanel/callContext";
import { useDispatch, useSelector } from "react-redux";
import { handleMaximizeTab } from "../../../../redux/widgets";
import ResponseAlert from "../../../../components/responseAlert";
import { useLocation } from "react-router-dom";
// Table Columns
const LeadsTable = ({
  classes,
  data,
  filters,
  sorting,
  stagesList,
  stagesListLoading,
  loading,
  columnsOrder,
  columnsVisibility,
  usersList,
  usersListLoading,
  usersObj,
  totalPeopleCount,
  tagsList,
  tempFilters,
  setTempFilters,
  tempSorting,
  setTempSorting,
}) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { dialNumber } = useContext(AppContext);
  const location = useLocation();
  const gridRef = useGridApiRef();
  const handleOpenWidget = (type, phone, email) => {
    if (!widgets[type]) {
      if (rightPositions.length) {
        if (
          rightPositions[rightPositions.length - 1].right + 1450 >
          window.innerWidth
        ) {
          handleAlert();
        } else {
          if (type === "sendTextOpen") {
            dispatch(
              handleMaximizeTab({
                type: type,
                action: true,
                tabDetail: {
                  toNumber: [phone],
                },
              })
            );
          } else {
            dispatch(
              handleMaximizeTab({
                type: type,
                action: true,
                tabDetail: {
                  toEmailList: [{ email: email, name: "" }],
                },
              })
            );
          }
        }
      } else {
        if (type === "sendTextOpen") {
          dispatch(
            handleMaximizeTab({
              type: type,
              action: true,
              tabDetail: {
                toNumber: [phone],
              },
            })
          );
        } else {
          dispatch(
            handleMaximizeTab({
              type: type,
              action: true,
              tabDetail: {
                toEmailList: [{ email: email, name: "" }],
              },
            })
          );
        }
      }
    } else {
      setErrorAlert({
        errorMsg: "You can't open this widget because already opened",
        errorType: "warning",
        isOpen: true,
      });
    }
  };

  const [columns, setColumns] = React.useState(
    getColumns(
      columnsOrder,
      stagesList,
      usersList,
      tagsList,
      dialNumber,
      handleOpenWidget
    )
  );
  const rightPositions = useSelector((state) => state.widgets.rightPositions);
  const widgets = useSelector((state) => state.widgets);
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });

  useEffect(() => {
    if (!stagesListLoading) {
      setColumns(
        getColumns(
          columnsOrder,
          stagesList,
          usersList,
          usersObj,
          dialNumber,
          handleOpenWidget
        )
      );
    }
  }, [columnsOrder, stagesListLoading]);

  //----- save state of drag and drop of columns
  useEffect(() => {
    gridRef.current.subscribeEvent("columnHeaderDragEnd", (params) => {
      let newCols = gridRef.current.getAllColumns();
      newCols.shift();
      newCols = newCols.map((c) => c.field);
      dispatch(setPeopleColumnsOrder(newCols));
    });
  }, []);
  const setFilters = (newFilters) => {
    dispatch(setPeopleFilter(newFilters));
  };
  const setSorting = (sortFilter) => {
    dispatch(setPeopleSort(sortFilter));
  };
  const applyFilters = (newFilters) => {
    let fl = newFilters ? newFilters : tempFilters;
    let appliedFilters = fl.reduce((prev, curr) => {
      if (
        curr.columnField &&
        curr.operatorValue &&
        (curr.value ||
          ["isNotEmpty", "isEmpty", "isBad", "isGood"].includes(
            curr.operatorValue
          ))
      ) {
        prev.push(curr);
      }
      return prev;
    }, []);

    if (
      JSON.stringify(appliedFilters) !== JSON.stringify(filters) ||
      JSON.stringify(sorting) != JSON.stringify(tempSorting)
    ) {
      dispatch(
        getLeadsListAPI({
          filters: JSON.stringify(appliedFilters),
          ...tempSorting,
          pageSize: 25,
          pageNumber: 1,
          resetList: true,
          from_agentprofit: location?.state?.from_agentprofit,
          agentprofit_agent_id: location?.state?.agentprofit_agent_id,
          agentprofit_start_date: location?.state?.agentprofit_start_date,
          agentprofit_end_date: location?.state?.agentprofit_end_date,
          agentprofit_team_id: location?.state?.agentprofit_team_id,
        })
      );
      dispatch(
        getLeadsIdsAfterFilter({
          ...tempSorting,
          filters: JSON.stringify(appliedFilters),
          pageSize: 25,
          pageNumber: 1,
          resetList: true,
        })
      );
      setFilters(appliedFilters);
      setSorting(tempSorting);
    }
  };
  // Detail view trigger for agent
  const onRowClick = async ({ id }) => {
    navigate(`/leads/people/details/${id}`);
  };
  const handleAlert = () => {
    setErrorAlert({
      errorMsg:
        "You can't open this widget because browser width is not enough for this!",
      errorType: "warning",
      isOpen: true,
    });
  };

  return (
    <>
      <Box
        sx={{
          margin: "10px 5px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="subtitle1" component="p">
          Showing {totalPeopleCount} People
        </Typography>
        {/* {setSearchText ? (
          <OutlinedInput
            value={searchText === false ? "" : searchText}
            onChange={(e) => setSearchText(e.target.value.toLowerCase())}
            endAdornment={
              <InputAdornment position="end">
                <IconButton edge="end">
                  <SearchIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
            }
            size="small"
            placeholder="Search..."
          />
        ) : null} */}
      </Box>
      <Grid container spacing={1}>
        <Grid item lg={filters?.length === 0 ? 12 : 9} md={12}>
          <Box className={classes.tableRoot}>
            <CustomFilterableDataGrid
              columns={columns}
              rows={data}
              columnVisibilityModel={columnsVisibility}
              apiRef={gridRef}
              sortModel={
                sorting.sortBy
                  ? [{ field: sorting.sortBy, sort: sorting.sortOrder }]
                  : []
              }
              filters={tempFilters}
              rowHeight={56}
              checkboxSelection
              loading={loading}
              filterMode="server"
              sortingMode="server"
              filterPanel={LeadsCustomFilters}
              toolbarPanel={LeadsCustomToolbar}
              onRowsScrollEnd={(e) => {
                if (totalPeopleCount > e.virtualRowsCount && !loading) {
                  dispatch(
                    getLeadsListAPI({
                      ...sorting,
                      pageNumber: Number(e.virtualRowsCount / 25) + 1,
                      pageSize: 25,
                      filters: JSON.stringify(filters),
                      from_agentprofit: location?.state?.from_agentprofit,
                      agentprofit_agent_id:
                        location?.state?.agentprofit_agent_id,
                      agentprofit_start_date:
                        location?.state?.agentprofit_start_date,
                      agentprofit_end_date:
                        location?.state?.agentprofit_end_date,
                      agentprofit_team_id: location?.state?.agentprofit_team_id,
                    })
                  );
                  // dispatch(
                  //   getLeadsListAPI({
                  //     ...sorting,
                  //     filters: JSON.stringify(filters),
                  //     pageSize: 25,
                  //     pageNumber: 1,
                  //     resetList: true,
                  //   })
                  // );
                }
              }}
              onSortModelChange={(e) => {
                setTempSorting(
                  e[0] ? { sortBy: e[0].field, sortOrder: e[0].sort } : {}
                );
              }}
              onRowClick={(param) => onRowClick(param)}
              onPreferencePanelClose={(opened) => {
                if (opened.openedPanelValue === "filters") {
                  applyFilters();
                }
              }}
              onColumnVisibilityModelChange={(visibility) => {
                dispatch(setPeopleColumnsVisibility(visibility));
              }}
              onFilterModelChange={(e) => {
                setTempFilters(e.items);
              }}
            />
          </Box>
        </Grid>
        {filters?.length > 0 ? (
          <Grid item lg={3} md={12}>
            <SidebarAllFilter
              filters={tempFilters}
              columns={columns}
              apiRef={gridRef}
              apiCallApplyFilter={(filter) => {
                setTempFilters(filter);
                applyFilters(filter);
              }}
            />
          </Grid>
        ) : null}
        <ResponseAlert
          open={errorAlert.isOpen}
          setOpen={() =>
            setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
          }
          alertType={errorAlert.errorType}
          alertMessage={errorAlert.errorMsg}
        />
      </Grid>
    </>
  );
};

export default withStyles(styleSheet, { name: "LeadsTableStyle" })(LeadsTable);
