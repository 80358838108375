import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles((theme) => ({
  dialogContent: {
    "&::-webkit-scrollbar": {
      width: 5,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#ddd",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#0B0909",
      borderRadius: 1,
    },
    "& .MuiPaper-root": {
      border: "1px solid rgba(0, 0, 0, 0.30) !important",
    },
    "& .MuiDivider-root": {
      borderColor: "rgba(0, 0, 0, 0.12) !important",
    },
  },
  taskDetailArea: {
    "&::-webkit-scrollbar": {
      width: 5,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#ddd",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#0B0909",
      borderRadius: 1,
    },
  },
  modalHeading: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  taskCardHeader: {
    fontWeight: "500 !important",
    fontSize: "15px !important",
    width: "100%",
  },
  projectCardHeaderAgentName: {
    fontWeight: "500 !important",
    fontSize: "15px !important",
    whiteSpace: "nowrap !important",
    overflow: "hidden  !important",
    textOverflow: "ellipsis  !important",
    width: "90% !important",
  },
  taskCardDes: {
    display: "-webkit-box !important",
    "-webkit-line-clamp": "1 !important",
    "-webkit-box-orient": "vertical !important",
    overflow: "hidden !important",
  },
  overDueDes: {
    display: "-webkit-box !important",
    "-webkit-line-clamp": "1 !important",
    "-webkit-box-orient": "vertical !important",
    overflow: "hidden !important",
    fontSize: "12.5px !important",
  },
  detailsHeader: {
    fontSize: "24px!important",
    fontWeight: "400!important",
    fontHeight: "133.4%!important",
    fontFamily: "AvenirNext !important",
  },
  detailsHeaderDueIN: {
    fontSize: "22px!important",
    fontWeight: "400!important",
    fontHeight: "133.4%!important",
    fontFamily: "AvenirNext !important",
  },
  detailsHeaderTask: {
    fontSize: "22px!important",
    fontWeight: "600!important",
    fontHeight: "133.4%!important",
    fontFamily: "AvenirNext !important",
  },
  otherHeader: {
    fontWeight: "500!important",
    fontSize: "20px!important",
    fontFamily: "AvenirNext !important",
  },
  otherSubHeader: {
    fontWeight: "400!important",
    fontSize: "13px!important",
    fontFamily: "AvenirNext !important",
  },
  textArea: {
    width: "100%!important",
    padding: "10px 13px!important",
    borderColor: "rgba(0, 0, 0, 0.23)!important",
    borderRadius: "8px!important",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "24px",
    letterSpacing: "0.15px",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: 5,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#ddd",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#0B0909",
      borderRadius: 1,
    },
  },
  textAreaTopRIght: {
    width: "100%!important",
    height: "100%!important",
    padding: "10px 45px 10px 13px!important",
    borderColor: "rgba(0, 0, 0, 0.23)!important",
    borderRadius: "8px!important",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "24px",
    letterSpacing: "0.15px",
    overflowY: "auto !important",
    "&::-webkit-scrollbar": {
      width: 5,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#ddd",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#0B0909",
      borderRadius: 1,
    },
  },
  textAreaForTopRight: {
    width: "100%!important",
    height: "100% !important",
    maxHeight: "820px !important",
    padding: "10px 45px 10px 13px!important",
    border: "1px solid #BEBEBF !important",
    backgroundColor: "#F7F7F9 !important",
    borderRadius: "8px!important",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "24px",
    letterSpacing: "0.15px",
    overflowY: "auto !important",
    "&::-webkit-scrollbar": {
      width: 5,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#ddd",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#0B0909",
      borderRadius: 1,
    },
  },
  textArea2: {
    width: "100%!important",
    padding: "10px 15px!important",
    height: "150px!important",
    borderColor: "rgba(0, 0, 0, 0.23)!important",
    borderRadius: "8px !important",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "24px",
    letterSpacing: "0.15px",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: 5,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#ddd",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#0B0909",
      borderRadius: 1,
    },
  },
  messageArea: {
    position: "relative",
    width: "100%!important",
    padding: "0px 0px 0px 15px!important",
    height: "200px!important",
    border: "1px solid rgba(0, 0, 0, 0.23)!important",
    borderRadius: "8px!important",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "24px",
    letterSpacing: "0.15px",
    display: "none",
  },
  chatCommentsBox: {
    height: "150px!important",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: 5,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#ddd",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#0B0909",
      borderRadius: 1,
    },
  },
  messageText: {
    position: "absolute!important",
    bottom: "-3px!important",
    left: "0px",
    width: "100%",
    height: "40px!important",
    "& .MuiInput-input": {
      padding: "8px 15px!important",
    },
    "& .MuiOutlinedInput-input": {
      padding: "8px 15px!important",
    },
    "& label.Mui-focused": {
      color: "rgba(0, 0, 0, 0.23)",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "rgba(0, 0, 0, 0.23)",
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)",
      },
    },
  },
  viewProjectButton: {
    width: "100%!important",
    borderColor: "#E6E6E6!important",
    backgroundColor: "#E6E6E6 !important",
    color: "#0B0909!important",
    fontFamily: "AvenirNext !important",
    fontStyle: "normal !important",
    boxShadow: "none !important",
    fontWeight: "400 !important",
    borderRadius: "40px !important",
    letterSpacing: "0.46px",
    "&:hover": {
      borderColor: "#0B0909!important",
      backgroundColor: "#d6d2d2 !important",
    },
    marginBottom: "10px !important",
  },
  button: {
    background: "#0B0909!important",
    color: "#fff!important",
    height: "37px!important",
    width: "100%!important",
    marginRight: "10px!important",
    fontFamily: "AvenirNext !important",
    fontStyle: "normal !important",
    fontWeight: "500 !important",
    borderRadius: "40px !important",
    boxShadow: "none !important",
    letterSpacing: "0.46px",
    "&:disabled": {
      color: "rgba(0, 0, 0, 0.26)!important",
      boxShadow: "none!important",
      backgroundColor: "rgba(0, 0, 0, 0.12)!important",
    },
    "&:hover": {
      background: "#0B0909!important",
    },
  },
  sendButton: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
    width: "47%",
    border: "1px solid #00BF41!important",
    color: "#00BF41 !important",
    backgroundColor: "white !important",
    borderRadius: "30px !important",
    "& path": {
      color: "#00BF41!important",
      fill: "#00BF41!important",
    },
  },
  approveButton: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
    width: "47%",
    border: "1px solid #6AC457 !important",
    color: "white !important",
    backgroundColor: "#6AC457 !important",
    borderRadius: "30px !important",
    boxShadow: "none !important",
  },
  clearButton: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
    width: "47%",
    border: "1px solid red !important",
    borderRadius: "30px !important",
    backgroundColor: "white !important",
    color: "red!important",
    "& path": {
      color: "red!important",
      fill: "red!important",
    },
  },
  closeButton: {
    width: "100%!important",
    borderColor: "#0B0909!important",
    color: "#0B0909!important",
    marginTop: "10px!important",
    fontFamily: "AvenirNext !important",
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    letterSpacing: "0.46px",
    "&:hover": {
      borderColor: "#0B0909!important",
    },
  },
  historyButton: {
    width: "100%!important",
    marginTop: "5px!important",
    fontSize: "12px!important",
    color: "#0B0909!important",
    fontFamily: "AvenirNext !important",
    textTransform: "capitalize !important",
  },
  deliverablesSection: {
    borderRadius: "8px",
    backgroundColor: "white",
    height: "160px",
    display: "flex",
    alignItems: "center",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    width: "95%",
    overflow: "hidden",
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      width: 5,
      height: 10,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#ddd",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0, 0, 0, 0.23)",
      borderRadius: 1,
    },
    "& .Mui-checked": {
      color: "#7E7E7E !important",
      boxShadow: "none !important",
    },
  },
  deliverables: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    flexWrap: "nowrap",
    gap: "10px",
  },
  uploadInput: {
    color: "#0B0909!important",
    minWidth: "100%!important",
    height: "165px",
    fontSize: "13px",
    fontWeight: "400",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    borderRadius: "8px",
    background: "rgba(233, 233, 233, 0.75)",
    width: "100%",
  },
  uploadAttachmentButton: {
    backgroundColor: "#fff !important",
    padding: "4px 8px !important",
    display: "flex !important",
    alignItems: "center !important",
    border: "1px solid rgba(0, 0, 0, 0.50) !important",
    "& span": {
      fontSize: "13.5px !important",
      fontFamily: "AvenirNext!important",
      fontWeight: "400!important",
      textTransform: "capitalize",
      padding: "0!important",
      paddingTop: "4px!important",
    },
  },
  deliverablesImg: {
    width: "100px",
    height: "130px",
    border: "1px solid #000",
  },
  deliverableCheckbox: {
    position: "absolute!important",
    top: "-2%!important",
    right: "-2%!important",
    color: "#7E7E7E !important",
  },
  deliverableFileName: {
    position: "absolute!important",
    bottom: "8px!important",
    color: "#fff!important",
    background: "#0B0909",
    padding: "2px 5px",
    fontSize: "14px",
    width: "98%",
    left: "1px",
  },
  dialogChangeButton: {
    background: "#0B0909!important",
    color: "#fff!important",
    height: "37px!important",
    marginRight: "10px!important",
    "&:hover": {
      background: "#0B0909!important",
    },
  },
  dialogCloseButton: {
    color: "#0B0909!important",
  },
  taskMemberBox: {
    alignItems: "flex-start",
    padding: "8px 0px",
    margin: "0px",
    position: "absolute",
    top: "70px",
    left: "70px",
    background: "#FFFFFF",
    boxShadow:
      "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
    borderRadius: "8px",
    height: "264px",
    overflowY: "scroll",
    zIndex: "9999",
    "&::-webkit-scrollbar": {
      width: 5,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#ddd",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#0B0909",
      borderRadius: 1,
    },
  },
  statusBox: {
    zIndex: 999999,
    alignItems: "flex-start",
    padding: "8px 0px",
    margin: "0px",
    position: "absolute",
    top: "25px",
    right: "-30px",
    background: "transparent",
  },
  statusBoxItem: {
    width: "100%",
    display: "flex !important",
    justifyContent: "center  !important",
    borderRadius: "20px   !important",
    padding: "5px 0px !important",
  },
}));
