import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  ListItemText,
  Button,
  Chip,
  TextField,
  Box,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { uploadDocument } from "../../../../redux/listings/listingDetail/uploadDocument";
import { updateDocument } from "../../../../redux/listings/listingDetail/updateDocument";
import docSample from "../../../../assets/images/docSample.png";
import processingIcon from "../../../../assets/images/processingIcon.png";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { useDispatch, useSelector } from "react-redux";
import { getData } from "../../../../utils/getData";
import AddListingDocument from "../../modals/addListingDocument";
import ResponseAlert from "../../../../components/responseAlert";
import { rejectDocument } from "../../../../redux/listings/listingDetail/rejectDocument";
import { ResetDocumentState } from "../../../../redux/listings/listingDetail";
import Dropzone from "react-dropzone";
import moment from "moment";
import dragIcon from "../../../../assets/dragIcon.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
const ListingDocPanel = ({
  classes,
  ListingData,
  type,
  title,
  hideAction,
  isGenerate,
}) => {
  const [open, setOpen] = useState(false);
  const [processing, setProcessing] = useState([]);
  const dispatch = useDispatch();
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const uploadDoc = useSelector(
    (item) => item.listings.listingDetail.uploadDoc
  );
  const updateDoc = useSelector(
    (item) => item.listings.listingDetail.updateDoc
  );
  const rejectDoc = useSelector(
    (item) => item.listings.listingDetail.rejectDoc
  );
  const agentRosterList = useSelector((item) => item.users.storedListOfUsers);

  useEffect(() => {
    if (uploadDoc.success && !uploadDoc.isLoading) {
      setErrorAlert({
        errorMsg: "You have successfully added the document",
        errorType: "success",
        isOpen: true,
      });
      dispatch(ResetDocumentState());
    } else if (!uploadDoc.isLoading && uploadDoc.errMsg) {
      setErrorAlert({
        errorMsg: JSON.stringify(uploadDoc.errMsg),
        errorType: "error",
        isOpen: true,
      });
    }
  }, [uploadDoc.success]);

  useEffect(() => {
    if (updateDoc.success && !updateDoc.isLoading) {
      setErrorAlert({
        errorMsg:
          updateDoc.success === "comment"
            ? "Comment is save successfully"
            : "You have successfully update the document status",
        errorType: "success",
        isOpen: true,
      });
      dispatch(ResetDocumentState());
    } else if (!updateDoc.isLoading && updateDoc.errMsg) {
      setErrorAlert({
        errorMsg: JSON.stringify(updateDoc.errMsg),
        errorType: "error",
        isOpen: true,
      });
    }
  }, [updateDoc.success]);

  useEffect(() => {
    if (rejectDoc.success && !rejectDoc.isLoading) {
      setErrorAlert({
        errorMsg: "You have successfully rejected the document",
        errorType: "success",
        isOpen: true,
      });
      dispatch(ResetDocumentState());
    } else if (!rejectDoc.isLoading && rejectDoc.errMsg) {
      setErrorAlert({
        errorMsg: JSON.stringify(rejectDoc.errMsg),
        errorType: "error",
        isOpen: true,
      });
    }
  }, [rejectDoc.success]);

  const downLoadFile = async (src) => {
    const result = await getData(
      `${process.env.REACT_APP_BASE_URL}api/transactions/signed-url` +
        "?src=" +
        encodeURIComponent(src)
    );
    if (result.status === 440) {
      window.location.href = "/login";
    }
    window.open(result.data, "_blank");
  };

  const handleFileChange = (file, data) => {
    setProcessing([...processing, data.id]);
    const Data = new FormData();
    Data.append("document", file);
    Data.append("address", ListingData.property_address);
    Data.append("id", data.id);
    dispatch(uploadDocument(Data));
  };
  const handleUpdateDoc = (data, index) => {
    let commentValue = document.getElementById(
      `listingDocumentComment${index}`
    ).value;
    dispatch(
      updateDocument({
        ...data,
        comment: commentValue ? commentValue : "",
        type: "status",
      })
    );
  };
  const handleUpdateComment = (data, value) => {
    if (value) {
      dispatch(updateDocument({ ...data, comment: value, type: "comment" }));
    }
  };
  const handleRejectDocument = (data) => {
    dispatch(rejectDocument({ reason: "", id: data.id }));
  };

  const handleDragEnter = (index) => {
    document.getElementById(`listings-documents-drag-${index}`).style.display =
      "flex";
  };
  const handleDragLeave = (index) => {
    document.getElementById(`listings-documents-drag-${index}`).style.display =
      "none";
  };
  const handleFileChangeDrop = (Files, data) => {
    if (Files[0]?.type?.split("/")[1] === "pdf") {
      handleFileChange(Files[0], data);
    } else {
      setErrorAlert({
        errorMsg: "Only Pdf file allow",
        errorType: "warning",
        isOpen: true,
      });
    }
  };
  return (
    <React.Fragment>
      <Card
        sx={{ backgroundColor: "white" }}
        className={classes.TransactionSalesRoot}
      >
        <CardHeader classes={{ title: classes.pageHeading }} title={title} />
        <CardContent>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Document Title</TableCell>
                  <TableCell align="left">Document Status</TableCell>
                  <TableCell align="center">Doc Thumbnail</TableCell>
                  <TableCell align="center">Document Notes Fields</TableCell>
                  <TableCell align="center">Approved By</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ListingData?.transaction_documents
                  ?.filter((item) => item.document?.type === type)
                  .map((row, index) => (
                    <Dropzone
                      accept={".pdf"}
                      noClick={true}
                      noKeyboard={true}
                      onDragEnter={() => handleDragEnter(index)}
                      onDragLeave={() => handleDragLeave(index)}
                      disabled={
                        row.status === "reject" ||
                        row.status === "missing" ||
                        row.status === "approved"
                          ? false
                          : true
                      }
                      onDrop={(acceptedFiles) => {
                        handleFileChangeDrop(acceptedFiles, row);
                        handleDragLeave(index);
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <TableRow
                          {...getRootProps()}
                          key={row.name}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            position: "relative",
                          }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            sx={{ width: "250px" }}
                          >
                            <Box
                              id={`listings-documents-drag-${index}`}
                              className={classes.dropzoneArea}
                            >
                              <Box className={classes.dropzoneAreaInner}>
                                <img
                                  src={dragIcon}
                                  alt="dragIcon"
                                  style={{ width: "25px", marginRight: "10px" }}
                                />{" "}
                                <Box>Drop files to upload.</Box>
                              </Box>
                            </Box>
                            {row.document_name || row.document?.name}
                          </TableCell>
                          <TableCell align="left">
                            {row.status === "submitted" && (
                              <Chip
                                size="small"
                                sx={{
                                  backgroundColor: "#0B0909",
                                  color: "white",
                                  minWidth: "100px",
                                  textTransform: "capitalize",
                                }}
                                label={row.status}
                              />
                            )}
                            {row.status === "missing" && (
                              <Chip
                                size="small"
                                variant="outlined"
                                color="error"
                                sx={{
                                  minWidth: "100px",
                                  textTransform: "capitalize",
                                }}
                                label={row.status}
                              />
                            )}
                            {row.status === "exempt" && (
                              <Chip
                                size="small"
                                sx={{
                                  backgroundColor: "rgba(0, 0, 0, 0.3)",
                                  color: "white",
                                  minWidth: "100px",
                                  textTransform: "capitalize",
                                }}
                                label={row.status}
                              />
                            )}
                            {row.status === "approved" && (
                              <Chip
                                size="small"
                                sx={{
                                  backgroundColor: "#66BB6A",
                                  color: "white",
                                  minWidth: "100px",
                                  textTransform: "capitalize",
                                }}
                                label={row.status}
                              />
                            )}
                          </TableCell>
                          <TableCell onClick={() => downLoadFile(row.file_src)}>
                            <center>
                              {row.status !== "missing" &&
                              row.status !== "exempt" ? (
                                <Box
                                  sx={{ position: "relative", width: "60px" }}
                                >
                                  <img
                                    src={
                                      processing.includes(row.id)
                                        ? processingIcon
                                        : row.file_src
                                        ? process.env.REACT_APP_DOCUMENT_IMG +
                                          encodeURIComponent(
                                            row.file_src.replace(".pdf", ".png")
                                          )
                                        : docSample
                                    }
                                    width="60px"
                                    style={{
                                      cursor: "pointer",
                                      border: "0.4px solid #000000",
                                    }}
                                    alt="doc"
                                  />
                                  <CheckCircleIcon
                                    sx={{
                                      position: "absolute",
                                      top: "5px",
                                      right: "-10px",
                                      color: "#4CAF50",
                                    }}
                                  />
                                </Box>
                              ) : isGenerate ? (
                                <Chip
                                  size="small"
                                  variant="outlined"
                                  color="success"
                                  sx={{
                                    minWidth: "100px",
                                    textTransform: "capitalize",
                                  }}
                                  label={"GENERATE"}
                                />
                              ) : null}
                            </center>
                          </TableCell>
                          <TableCell align="right">
                            <TextField
                              multiline
                              onBlur={(e) =>
                                handleUpdateComment(
                                  { id: row.id },
                                  e.target.value
                                )
                              }
                              sx={{
                                width: "100%", // Set a stable width
                                maxWidth: "250px", // Optional max-width to constrain the field
                                minWidth: "170px", // Optional min-width to ensure consistent sizing
                                resize: "none", // Prevent resizing for a multiline field
                              }}
                              rows={2}
                              id={`listingDocumentComment${index}`}
                              defaultValue={row.comment}
                              placeholder="label"
                            />
                          </TableCell>
                          <TableCell align="right">
                            {((row.status !== "missing" &&
                              row.status === "approved") ||
                              row.status === "exempt") && (
                              <ListItemText
                                primary={
                                  row.status === "exempt"
                                    ? row.exempt_by_id
                                      ? agentRosterList?.list.find(
                                          (item) =>
                                            item.id ===
                                            Number(row.exempt_by_id || 1)
                                        )?.full_name
                                      : ""
                                    : row.approved_by
                                }
                                secondary={
                                  row.status === "exempt"
                                    ? moment(row.updatedAt).format("L")
                                    : row.approval_time
                                    ? moment(row.approval_time).format("L")
                                    : null
                                }
                              />
                            )}
                          </TableCell>
                          <TableCell align="center" sx={{ width: "100px" }}>
                            {row.status === "submitted" && (
                              <Box>
                                <Button
                                  onClick={() =>
                                    handleUpdateDoc(
                                      { id: row.id, approved: true },
                                      index
                                    )
                                  }
                                  variant="outlined"
                                  size="small"
                                  color="success"
                                  classes={{
                                    root: classes.documentTableButton,
                                  }}
                                >
                                  Approve
                                </Button>
                                <br />
                                <Button
                                  variant="outlined"
                                  size="small"
                                  color="error"
                                  onClick={() => handleRejectDocument(row)}
                                  classes={{
                                    root: classes.documentTableButton,
                                  }}
                                  sx={{ marginTop: "10px" }}
                                >
                                  Reject
                                </Button>
                              </Box>
                            )}{" "}
                            {(row.status === "missing" ||
                              row.status === "approved") && (
                              <Button
                                variant="outlined"
                                size="small"
                                color="inherit"
                                classes={{ root: classes.documentTableButton }}
                                startIcon={<UploadFileIcon />}
                                component="label"
                              >
                                {row.status === "approved"
                                  ? "Replace"
                                  : "Upload"}
                                <input
                                  onChange={(e) =>
                                    handleFileChange(e.target.files[0], row)
                                  }
                                  hidden
                                  accept=".pdf"
                                  multiple
                                  type="file"
                                />
                              </Button>
                            )}
                            {row.status === "exempt" &&
                              row.status !== "submitted" && (
                                <Button
                                  onClick={() =>
                                    handleUpdateDoc(
                                      { id: row.id, exempt: false },
                                      index
                                    )
                                  }
                                  variant="outlined"
                                  size="small"
                                  color="inherit"
                                  classes={{
                                    root: classes.documentTableButton,
                                  }}
                                >
                                  Undo
                                </Button>
                              )}
                            {row.document.can_exempted &&
                              row.status !== "submitted" &&
                              row.status !== "exempt" &&
                              row.status !== "approved" && (
                                <Button
                                  onClick={() =>
                                    handleUpdateDoc(
                                      { id: row.id, exempt: true },
                                      index
                                    )
                                  }
                                  sx={{ marginTop: "8px" }}
                                  variant="outlined"
                                  size="small"
                                  color="inherit"
                                  classes={{
                                    root: classes.documentTableButton,
                                  }}
                                >
                                  Exempt
                                </Button>
                              )}
                            {row.status === "reject" && (
                              <Button
                                sx={{ marginTop: "8px" }}
                                variant="outlined"
                                size="small"
                                component="label"
                                color="inherit"
                                classes={{ root: classes.documentTableButton }}
                              >
                                Re upload
                                <input
                                  onChange={(e) =>
                                    handleFileChange(e.target.files[0], row)
                                  }
                                  hidden
                                  accept=".pdf"
                                  multiple
                                  type="file"
                                />
                              </Button>
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                    </Dropzone>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          {!hideAction ? (
            <Button
              variant="outlined"
              color="inherit"
              onClick={() => setOpen(true)}
              className={classes.saveModalButton}
            >
              Add Document
            </Button>
          ) : null}
        </CardContent>
      </Card>
      <AddListingDocument
        open={open}
        ListingData={ListingData}
        setOpen={setOpen}
      />
      <Backdrop
        color="inherit"
        invisible={true}
        open={uploadDoc.isLoading || updateDoc.isLoading || rejectDoc.isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
};

export default withStyles(styleSheet, {
  name: "ListingDocPanelStyle",
})(ListingDocPanel);
