import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addAgentRecruitmentAppointments } from "../agents/agentRecruitment/addAgentRecruitmentAppointments";
import {
  addAgentRecruitmentTasks,
  markTaskAsCompleteAgentRecruitment,
} from "../agents/agentRecruitment/addAgentRecruitmentTasks";
import { addAgentRecruitmentTags } from "../agents/agentRecruitment/addAgentRecruitmentTags";
import { removeAgentRecruitmentTags } from "../agents/agentRecruitment/removeAgentRecruitmentTags";
import { deleteAgentRecruitmentTask } from "../agents/agentRecruitment/deleteAgentRecruitmentTask";
import { updateAgentRecruitmentTask } from "../agents/agentRecruitment/updateAgentRecruitmentTask";
import { updateAgentRecruitmentAppointment } from "../agents/agentRecruitment/updateAgentRecruitmentAppointment";
import { deleteAgentRecruitmentAppointment } from "../agents/agentRecruitment/deleteAgentRecruitmentAppointment";
import { createNoteAgentRecruitment } from "../agents/agentRecruitment/createNoteAgentRecruitment";
import { createTextAgentRecruitment } from "../agents/agentRecruitment/createTextAgentRecruitment";
import { callLogAgentRecruitment } from "../agents/agentRecruitment/callLogAgentRecruitment";
import { sendEmailAgentRecruitment } from "../agents/agentRecruitment/sendEmailAgentRecruitment";
import { setInfoAgentRecruitment } from "../agents/agentRecruitment/setInfoAgentRecruitment";
import { updateStageNameAgentRecruitment } from "../agents/agentRecruitment/updateStageNameAgentRecruitment";
import { deleteFileAPI } from "../agents/agentRecruitment/deleteFile";
import { sendOnboardingEmail } from "./agentRosterDetail/sendOnboardingEmail";
import { error } from "pdf-lib";
const initialState = {
  loading: "",
  tagsStatus: {
    pending: "",
    success: "",
    error: "",
  },
  updateTimelineRecords: {
    pending: false,
    success: false,
    error: false,
  },
  currentTab: {
    data: {},
    loading: "",
    success: "",
    error: "",
  },
  agentProfile: {
    deleteFile: {
      pending: false,
      success: false,
      error: false,
    },
    sendEmail: {
      loading: "",
      success: "",
      errMsg: "",
    },
    realtor_files: {
      status: {
        pending: false,
        success: false,
        error: false,
      },
    },
    toEmailList: [],
    list: {},
    filterlist: [],
    modalActions: {
      loading: false,
      success: false,
      errMsg: null,
    },
  },
  agentInfo: {
    loading: false,
    success: false,
    errMsg: null,
  },
  sendOnboarding: {
    isLoading: false,
    data: {},
    errMsg: null,
  },
  errMsg: null,
};

export const getAgentRecruitingProfile = createAsyncThunk(
  "agentProfile/getAgentRecruitingProfile",
  async (data, thunkAPI) => {
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_BASE_URL}api/recruitment/${data.id}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            "Content-Type": "application/json",
          },
        }
      );

      return { data: resp.data, fil: data?.filteredDataIds || null };
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const uploadAgentFile = createAsyncThunk(
  "agentProfile/uploadAgentFile",
  async (data, thunkAPI) => {
    let { handleSuccess, handleError, formData } = data;
    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/recruitment/upload-file`,
        formData || data,
        {
          headers: {
            "Content-type": "multipart/form-data",
          },
        }
      );
      if (handleSuccess) {
        handleSuccess();
      }
      return resp.data;
    } catch (error) {
      if (error.response) {
        if (handleError) {
          handleError(
            error.response?.data?.message ||
              error.response.data?.replace(/<[^>]*>/g, "")
          );
        }
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        if (handleError) {
          handleError(error.message);
        }
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

const agentProfile = createSlice({
  name: "agentProfile",
  initialState,
  reducers: {
    modalSubmitSuccess: (state, action) => {
      state.agentProfile.modalActions = initialState.agentProfile.modalActions;
    },
    selectedEmailList: (state, action) => {
      if (action?.payload) {
        console.log(action.payload, "<<<");
        state.agentProfile.toEmailList = action.payload;
      }
    },
  },
  extraReducers: {
    [getAgentRecruitingProfile.pending]: (state) => {
      state.loading = true;
      state.agentProfile.list = {};
    },
    [getAgentRecruitingProfile.fulfilled]: (state, action) => {
      state.loading = false;
      state.agentProfile.list = action.payload.data;
      if (action?.payload?.fil) {
        state.agentProfile.filterlist = action.payload.fil;
      }
    },
    [getAgentRecruitingProfile.rejected]: (state, action) => {
      state.loading = false;
      state.errMsg = action?.error?.message;
    },
    [addAgentRecruitmentAppointments.pending]: (state, action) => {
      state.agentProfile.modalActions.loading = true;
    },
    [addAgentRecruitmentAppointments.fulfilled]: (state, action) => {
      state.agentProfile.modalActions.loading = false;
      state.agentProfile.modalActions.success = true;
      state.agentProfile.list = {
        ...state.agentProfile.list,
        realtor_appointments: [
          ...state.agentProfile.list.realtor_appointments,
          action.payload,
        ],
      };

      state.errMsg = null;
    },
    [addAgentRecruitmentAppointments.rejected]: (state, action) => {
      state.agentProfile.modalActions.errMsg = action.payload;
    },

    // delete file
    [deleteFileAPI.pending]: (state, action) => {
      state.agentProfile.deleteFile.pending = true;
    },
    [deleteFileAPI.fulfilled]: (state, action) => {
      let newRecruitmentFiles = [...state.agentProfile.list.realtor_files];
      state.agentProfile.list.realtor_files = newRecruitmentFiles.filter(
        (item) => item.id !== action.payload.fileId
      );
      state.agentProfile.deleteFile.pending = false;
    },
    [deleteFileAPI.rejected]: (state, action) => {
      state.agentProfile.deleteFile.pending = false;
    },

    [addAgentRecruitmentTasks.pending]: (state, action) => {
      state.agentProfile.modalActions.loading = true;
    },
    [addAgentRecruitmentTasks.fulfilled]: (state, action) => {
      state.agentProfile.modalActions.loading = false;
      state.agentProfile.modalActions.success = true;
      state.agentProfile.list = {
        ...state.agentProfile.list,
        realtor_tasks: [
          ...state.agentProfile.list.realtor_tasks,
          action.payload,
        ],
      };

      state.errMsg = null;
    },
    [addAgentRecruitmentTasks.rejected]: (state, action) => {
      state.agentProfile.modalActions.errMsg = action.payload;
    },
    [addAgentRecruitmentTags.pending]: (state, action) => {
      state.agentProfile.modalActions.loading = true;
    },
    [addAgentRecruitmentTags.fulfilled]: (state, action) => {
      state.agentProfile.modalActions.loading = false;
      state.agentProfile.modalActions.success = true;
      state.agentProfile.list = {
        ...state.agentProfile.list,
        realtor_tags: [...action.payload.realtor_tags],
      };

      state.errMsg = null;
    },
    [addAgentRecruitmentTags.rejected]: (state, action) => {
      state.agentProfile.modalActions.errMsg = action.payload;
    },

    [removeAgentRecruitmentTags.pending]: (state, action) => {
      state.tagsStatus.pending = true;
    },
    [removeAgentRecruitmentTags.fulfilled]: (state, action) => {
      state.tagsStatus.pending = false;
      state.agentProfile.list = {
        ...state.agentProfile.list,
        realtor_tags: [...action.payload.realtor_tags],
      };

      state.errMsg = null;
      state.loading = false;
    },
    [removeAgentRecruitmentTags.rejected]: (state, action) => {
      state.tagsStatus.pending = true;
    },

    [deleteAgentRecruitmentTask.pending]: (state, action) => {
      state.agentProfile.modalActions.loading = true;
    },
    [deleteAgentRecruitmentTask.fulfilled]: (state, action) => {
      state.agentProfile.list = {
        ...state.agentProfile.list,
        realtor_tasks: state.agentProfile.list.realtor_tasks.filter(
          (task) => task.id !== action.payload.arTask.id
        ),
      };
      state.agentProfile.modalActions.success = true;
      state.agentProfile.modalActions.loading = false;
    },

    [deleteAgentRecruitmentTask.rejected]: (state, action) => {},
    [updateAgentRecruitmentTask.pending]: (state, action) => {
      state.agentProfile.modalActions.loading = true;
    },
    [updateAgentRecruitmentTask.fulfilled]: (state, action) => {
      let foundIndex = state.agentProfile.list.realtor_tasks.findIndex(
        (x) => x.id == action.payload.id
      );
      state.agentProfile.list.realtor_tasks[foundIndex] = action.payload;
      state.agentProfile.modalActions.success = true;
      state.agentProfile.modalActions.loading = false;
    },
    [updateAgentRecruitmentTask.rejected]: (state, action) => {
      state.agentProfile.modalActions.loading = false;
    },

    [updateAgentRecruitmentAppointment.pending]: (state, action) => {
      state.agentProfile.modalActions.loading = true;
    },
    [updateAgentRecruitmentAppointment.fulfilled]: (state, action) => {
      let foundIndex = state.agentProfile.list.realtor_appointments.findIndex(
        (x) => x.id === action.payload.id
      );
      state.agentProfile.list.realtor_appointments[foundIndex] = {
        ...state.agentProfile.list.realtor_appointments[foundIndex],
        ...action.payload,
      };
      state.agentProfile.modalActions.success = true;
      state.agentProfile.modalActions.loading = false;
    },
    [updateAgentRecruitmentAppointment.rejected]: (state, action) => {
      state.agentProfile.modalActions.success = false;
      state.agentProfile.modalActions.loading = false;
    },

    [deleteAgentRecruitmentAppointment.pending]: (state, action) => {
      state.agentProfile.modalActions.loading = true;
    },
    [deleteAgentRecruitmentAppointment.fulfilled]: (state, action) => {
      state.agentProfile.list = {
        ...state.agentProfile.list,
        realtor_appointments:
          state.agentProfile.list.realtor_appointments.filter(
            (task) => task.id !== action.payload.id
          ),
      };
      state.agentProfile.modalActions.success = true;
      state.agentProfile.modalActions.loading = false;
    },
    [deleteAgentRecruitmentAppointment.rejected]: (state, action) => {
      state.agentProfile.modalActions.loading = false;
    },

    [markTaskAsCompleteAgentRecruitment.fulfilled]: (state, action) => {
      if (action.payload.statusUpdate) {
        let index = state.agentProfile.list.realtor_tasks.findIndex(
          (item) => item.id === action.payload.id
        );
        state.agentProfile.list.realtor_tasks[index].isCompleted =
          action.payload.isCompleted;
        state.agentProfile.modalActions.success = true;
      }
    },

    [createNoteAgentRecruitment.pending]: (state, action) => {
      state.currentTab.loading = true;
    },
    [createNoteAgentRecruitment.fulfilled]: (state, action) => {
      if (action?.payload) {
        state.currentTab = action.payload;
      }
      state.currentTab.loading = false;
      state.currentTab.success = true;
    },
    [createNoteAgentRecruitment.rejected]: (state, action) => {
      state.agentProfile.modalActions.loading = false;
    },

    [createTextAgentRecruitment.pending]: (state, action) => {
      state.currentTab.loading = true;
    },
    [createTextAgentRecruitment.fulfilled]: (state, action) => {
      if (action?.payload) {
        state.currentTab = action.payload;
      }
      state.currentTab.loading = false;
      state.currentTab.success = true;
    },
    [createTextAgentRecruitment.rejected]: (state, action) => {
      state.currentTab.loading = false;
    },

    [callLogAgentRecruitment.pending]: (state, action) => {
      state.currentTab.loading = true;
    },
    [callLogAgentRecruitment.fulfilled]: (state, action) => {
      if (action?.payload) {
        state.currentTab = action?.payload;
      }
      state.currentTab.loading = false;
      state.currentTab.success = true;
    },
    [callLogAgentRecruitment.rejected]: (state, action) => {
      state.currentTab.loading = false;
    },

    [sendEmailAgentRecruitment.pending]: (state, action) => {
      state.currentTab.error = false;
      state.currentTab.loading = true;
      state.currentTab.success = false;
    },
    [sendEmailAgentRecruitment.fulfilled]: (state, action) => {
      state.currentTab = action.payload;
      state.currentTab.loading = false;
      state.currentTab.success = true;
    },
    [sendEmailAgentRecruitment.rejected]: (state, action) => {
      state.currentTab.loading = false;
      state.currentTab.errMsg = true;
    },

    [setInfoAgentRecruitment.pending]: (state, action) => {
      state.agentInfo.loading = true;
      state.agentInfo.success = "false";
    },
    [setInfoAgentRecruitment.fulfilled]: (state, action) => {
      state.agentProfile.list = {
        ...state.agentProfile.list,
        ...action.payload.agentRecruitment,
      };
      state.agentInfo.loading = false;
      state.agentInfo.success = "true";
    },
    [setInfoAgentRecruitment.rejected]: (state, action) => {
      state.agentInfo.loading = false;
      state.agentInfo.success = "false";
    },

    [updateStageNameAgentRecruitment.pending]: (state, action) => {
      state.agentInfo.loading = true;
      state.agentInfo.success = "false";
    },
    [updateStageNameAgentRecruitment.fulfilled]: (state, action) => {
      state.agentProfile.list = {
        ...state.agentProfile.list,
        ...action.payload.agentRecruitment,
      };
      state.agentInfo.loading = false;
      state.agentInfo.success = "true";
    },
    [updateStageNameAgentRecruitment.rejected]: (state, action) => {
      state.agentInfo.loading = false;
      state.agentInfo.success = "false";
    },

    [uploadAgentFile.pending]: (state) => {
      state.agentProfile.realtor_files.status.pending = true;
    },
    [uploadAgentFile.fulfilled]: (state, action) => {
      if (action?.payload) {
        state.agentProfile.list = {
          ...state.agentProfile.list,
          realtor_files: [
            ...state.agentProfile.list.realtor_files,
            action.payload,
          ],
        };
      }
      state.agentProfile.realtor_files.status.pending = false;
    },
    [uploadAgentFile.rejected]: (state, action) => {
      state.agentProfile.realtor_files.status.pending = false;
      state.agentProfile.realtor_files.status.error = true;
    },
    /* send onboarding email */
    [sendOnboardingEmail.pending]: (state) => {
      state.sendOnboarding.isLoading = true;
    },
    [sendOnboardingEmail.fulfilled]: (state, action) => {
      state.sendOnboarding.isLoading = false;
      state.sendOnboarding.data = action.payload;
    },
    [sendOnboardingEmail.rejected]: (state, action) => {
      state.sendOnboarding.isLoading = false;
      state.sendOnboarding.errMsg = action.payload;
    },
  },
});

export const { modalSubmitSuccess, selectedEmailList } = agentProfile.actions;

export default agentProfile.reducer;
